import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { GetUsersList,AddNewUser,UpdateUser, GetSingleUser } from "../api/UserApis"

const initialState = {
  usersList: [],
  status: 'idle',
  screenMode: 'list',
  singleUser:[]
};

export const AddNewUserAsync = createAsyncThunk(
  'userProfile/addnew',
  async (data) => {
    const response = await AddNewUser(data.formdata,data.token);
    return response.data;
  }
);
export const GetSingleUserAsync = createAsyncThunk(
  'userProfile/getSpecific',
  async (data) => {
    
    const response = await GetSingleUser(data.params,data.token);
   
    return response.data;
  }
);
export const UpdateUserAsync = createAsyncThunk(
  'userProfile/update',
  async (data) => {
    
    const response = await UpdateUser(data.formdata,data.token);
    return response.data;
  }
);
export const GetUserListAsync = createAsyncThunk(
  'userProfile/list',
  async (data) => {
    const response = await GetUsersList(data);
    return response.data;
  }
);
export const UserSlice = createSlice({
  name: 'userProfile',
  initialState,
  /*  reducers: {
      changeScreen: (state, action) => {
       // state.screen = action.payload
      },
  
    },*/
  extraReducers: (builder) => {
    builder
      .addCase(GetUserListAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetUserListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
    
        state.usersList = action.payload.list
        // state.profileData = action.payload.token;
      })
      .addCase(GetSingleUserAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetSingleUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
    
        state.singleUser = action.payload.list
        // state.profileData = action.payload.token;
      })
      .addCase(AddNewUserAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(AddNewUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success(action.payload.message)
        // state.profileData = action.payload.token;
      })
      .addCase(UpdateUserAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(UpdateUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success(action.payload.message)
        // state.profileData = action.payload.token;
      })
  },
});



// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`


export const getUsersList = (state) => state.userProfile.usersList;

export const getSpecificUser = (state) => state.userProfile.singleUser;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/*export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};*/

export default UserSlice.reducer;

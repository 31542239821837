import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { GetAllATMList,AddNewATM,GetAllActiveATM,update,GetSpecific } from "../api/ATMApis"

const initialState = {
  atmList: [],
  singleatm: [],
  activeatms:[],
  status: 'idle',
  screenMode: 'list'
};

export const GetATMListAsync = createAsyncThunk(
  'ATMDetails/list',
  async (data) => {
    const response = await GetAllATMList(data);
    return response.data;
  }
);
export const GetSingleATMAsync = createAsyncThunk(
  'ATMDetails/getspecific',
  async (data) => {
  
    const response = await GetSpecific(data.params,data.token);
    return response.data;
  }
);
export const UpdateATMAsync = createAsyncThunk(
  'ATMDetails/update',
  async (data) => {
    const response = await update(data.formdata,data.token);
    return response.data;
  }
);
export const AddNewATMAsync = createAsyncThunk(
  'ATMDetails/addnew',
  async (data) => {
    const response = await AddNewATM(data.formdata,data.token);
    return response.data;
  }
);

export const GetAllActiveATMAsync = createAsyncThunk(
  'ATMDetails/getactive',
  async (data) => {
    const response = await GetAllActiveATM(data);
    
    return response.data;
  }
);


export const ATMSlice = createSlice({
  name: 'ATMDetails',
  initialState,
  /*  reducers: {
      changeScreen: (state, action) => {
       // state.screen = action.payload
      },
  
    },*/
  extraReducers: (builder) => {
    builder
      .addCase(GetATMListAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetATMListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.atmList = action.payload.list
        // state.profileData = action.payload.token;
      })
      .addCase(GetSingleATMAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetSingleATMAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.singleatm = action.payload.list
        // state.profileData = action.payload.token;
      })
      .addCase(GetAllActiveATMAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetAllActiveATMAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.activeatms = action.payload
        // state.profileData = action.payload.token;
      })
      .addCase(AddNewATMAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(AddNewATMAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success(action.payload.message)
        // state.profileData = action.payload.token;
      })
      .addCase(UpdateATMAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(UpdateATMAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success(action.payload.message)
        // state.profileData = action.payload.token;
      })
  },
});



// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`


export const getATMList = (state) => state.ATMDetails.atmList;
export const getATM = (state) => state.ATMDetails.singleatm;
export const getactiveATM = (state) => state.ATMDetails.activeatms;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/*export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};*/

export default ATMSlice.reducer;

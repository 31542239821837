
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import {

    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBContainer,
    MDBBtn,
    MDBInput


} from 'mdb-react-ui-kit';
import { getAdminToken } from '../../reducers/AuthSlice'

import { UpdateTicketAsync, GetSpecificTicketAsync, TicketDetails } from '../../reducers/TicketSlice'
const priorityMap = { "1": "High", "2": "Medium", "3": "Low" };

export const UpdateTicketDetails = () => {

    const token = useSelector(getAdminToken);
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch();
    // const location = useLocation()
    // const data = location.state.data
    const [formdata, setformdata] = useState({
        details: "",
        description: "",
        priority: "",
        ticketid: ''
    })
    useEffect(() => {

        dispatch(GetSpecificTicketAsync({ params: { ticketid: params.ticketid, }, token }));
    }, []
    );
    const ticket = useSelector(TicketDetails);
    console.log(ticket, "UpdateTicketDetails")

    useEffect(() => {
        if (ticket?.length > 0) {
            setformdata(ticket[0]);
        }

    }, [ticket]
    );

    const handleAllChanges = (event) => {
        setformdata(
            (PrevDict) => {
                return {
                    ...PrevDict,
                    [event.target.name]: event.target.value
                }
            }
        )
    }
    const updateTicket = () => {
        dispatch(UpdateTicketAsync({ formdata, token })).then(() => {
            navigate("/home/backoffice/Tickets")
        });
    }


    console.log("return se upr")
    return (
        <MDBContainer className="py-4 text-start ">
            <MDBCard alignment='left' style={{ borderColor: "#1c1c52" }} >
                <MDBRow className='px-1'>
                    <MDBCol lg='10' md='8' sm='8'>
                        <h3 className='text-start m-3' style={{ color: "#13538a" }}>Update Ticket</h3>
                    </MDBCol>
                    <MDBCol lg='2' md='4' sm='4'>
                        <Link to={"/home/backoffice/Tickets"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3 px-3 py-2'> Back</MDBBtn></Link>
                    </MDBCol>
                    {ticket && (
                        <MDBCardBody>
                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                                    Details of Ticket: {ticket[0]?.ticketid}
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ color: "#1c1c52" }}>
                                    <form>
                                        <div className="text-start">
                                            <MDBRow>

                                                <MDBCol lg="6" className="p-2">
                                                    <MDBInput label="Details" type="text" error="wrong"
                                                        success="right" name="details" value={formdata?.details??""} onChange={handleAllChanges} />
                                                </MDBCol>



                                                <MDBCol lg="6" className="p-2">
                                                    <select label="Priority" className="browser-default custom-select p-2" style={{ borderRadius: "5px", borderColor: "#BDBDBD", width: "50%" }}
                                                        name='priority' onChange={handleAllChanges} value={priorityMap[formdata.priority]}
                                                    >
                                                        {/* <option value={formdata.priority}>{priorityMap[formdata.priority]}</option> */}
                                                        <option value="1">High</option>
                                                        <option value="2">Medium</option>
                                                        <option value="3">Low</option>
                                                    </select>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol lg="12" className="p-2">
                                                    <label>Description</label>
                                                    <textarea className="form-control text-dark mb-2" rows="4"
                                                        group
                                                        type="text"
                                                        validate
                                                        error="wrong"
                                                        success="right"
                                                        name="description"
                                                        value={formdata.description}
                                                        onChange={handleAllChanges}
                                                    ></textarea>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>


                                        <div className="text-center w-100">
                                            <MDBBtn type={"button"} className="mx-2  my-5" style={{ backgroundColor: "#13538a" }} onClick={() => {
                                                let allow = true;
                                                if (!formdata.details) {
                                                    toast.error("Detais not provided.")
                                                    allow = false;
                                                }
                                                else if (!formdata.description) {
                                                    toast.error("Description not provided.")
                                                    allow = false;
                                                }

                                                else if (!formdata.status) {
                                                    toast.error("Status not provided.")
                                                    allow = false;
                                                }
                                                else if (!formdata.priority) {
                                                    toast.error("Priority not provided.")
                                                    allow = false;
                                                }

                                                else if (allow) {
                                                    updateTicket()
                                                }
                                            }}>Update Ticket</MDBBtn>
                                        </div>

                                    </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    )}
                </MDBRow>
            </MDBCard>

        </MDBContainer>

    );
}


import apiUtil from '../utilities/apiHelper'


export function GetTickets(data) {
  return apiUtil.getApi('admin/ticket/getall', data.token)
}

export function GetAssignedTickets(data) {
  return apiUtil.getApi('admin/assign/getallassigned', data.token)
}

export function GetCompletedTickets(data) {
  return apiUtil.getApi('admin/ticket/getcompleted', data.token,{startDate:data.startDate,endDate:data.endDate})
}

export function TransferTicket(data,token) {
  return apiUtil.postApi(`admin/assign/transferTicket/${data.ticketid}/${data.id}`, token,data)
}

export function GetSpecificAssignTicket(data,token) {
  
  return apiUtil.getApi(`admin/assign/getspecific/${data.ticketid}/${data.id}`,token)
}
export function GetClaimRequest(data) {
  return apiUtil.getApi('admin/claim-request/getallclaimrequest', data.token)
}
export function GetSpecificRequest(data,token) {
  
  return apiUtil.getApi(`admin/claim-request/getRequest/${data.claimid}`,token)
}
export function ClaimedRequest(data,token) {

  return apiUtil.postApi(`admin/claim-request/setRequestClaimed`,token,data)
}
export function RejectRequest(data,token) {

  return apiUtil.postApi(`admin/claim-request/setRequestRjected/${data.ticketid}`,token,data)
}
export function updateticketDetails(data,token) {

  return apiUtil.postApi(`admin/ticket/update/${data.ticketid}`,token,data)
}
export function GetClaimRequestComments(data,token) {

  return apiUtil.getApi(`admin/claim-request/getComments/${data.claimid}`,token)
}
export function GetAssignComments(data,token) {

  return apiUtil.getApi(`admin/assign/getComments/${data.ticketid}/${data.id}`,token)
}

export function GetComments(data,token) {

  return apiUtil.getApi(`admin/ticket/getComments/${data.ticketid}`,token)
}
export function CreateTicket(data,token) {
  return apiUtil.postApi('admin/ticket/create' ,token,data)
}
export function AssignTicket(data,token) {

  return apiUtil.postApi('admin/assign/assignticket',token,data)
}
export function DiscardTicket(data,token) {

  return apiUtil.postApi('admin/ticket/discardticket',token,data)
}

export function GetSpecificTicket(data,token) {
  return apiUtil.getApi(`admin/ticket/getspecific/${data.ticketid}`,token)
}



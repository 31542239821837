
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import {

    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBContainer,
    MDBBtn,
    MDBInput


} from 'mdb-react-ui-kit';
import { getAdminToken } from '../../reducers/AuthSlice'

import { GetSingleATMAsync, UpdateATMAsync, getATM } from '../../reducers/ATMSlice'

const statusMap = { "1": "Active", "0": "Inactive" };
export const UpdateATM = () => {
    const params = useParams()
    const token = useSelector(getAdminToken);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    // const location = useLocation()
    // const data = location.state.data

    useEffect(() => {

        dispatch(GetSingleATMAsync({ params: { atmid: params.atmid }, token }));
    }, []);
    const atm = useSelector(getATM);
    const [formdata, setformdata] = useState({
        details: "",
        gpslat: "",
        gpslong: "",
        location: "",
        status: '',
        atmid: ''
    })

    useEffect(() => {

        setformdata(atm);
    }, []
    );
    useEffect(() => {
        if (atm) {
            setformdata(atm)
        }
    }, [atm]);

    const handleAllChanges = (event) => {
        setformdata(
            (PrevDict) => {
                return {
                    ...PrevDict,
                    [event.target.name]: event.target.value
                }
            }
        )
    }
    const updateAtm = () => {
        dispatch(UpdateATMAsync({ formdata, token })).then(() => {
            navigate("/home/backoffice/ATMlist")
        });
    }


    return (
        <MDBContainer className="py-4 text-start ">
            <MDBCard alignment='left' style={{ borderColor: "#1c1c52" }} >
                <MDBRow className='px-1'>
                    <MDBCol lg='10' md='8' sm='8'>
                        <h3 className='text-start m-3' style={{ color: "#13538a" }}>Update ATM</h3>
                    </MDBCol>
                    <MDBCol lg='2' md='4' sm='4'>
                        <Link to={"/home/backoffice/ATMlist"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3 px-3 py-2'> Back</MDBBtn></Link>
                    </MDBCol>
                    {
                        atm && (
                            <MDBCardBody>
                                <MDBRow className='px-2' >
                                    <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                                        Details of ATM: {atm.details}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='px-2' >
                                    <MDBCol lg='12' className='p-2 text-start' style={{ color: "#1c1c52" }}>
                                        <form>
                                            <div className="text-start">
                                                <MDBRow>

                                                    <MDBCol lg="6" className="py-2">
                                                        <MDBInput label="Name" type="text" error="wrong"
                                                            success="right" name="details" value={formdata.details} onChange={handleAllChanges} />
                                                    </MDBCol>
                                                    <MDBCol lg="6" className="py-2">
                                                        <MDBInput label="Location" icon="envelope" type="text" error="wrong"
                                                            success="right" name="location" value={formdata.location} onChange={handleAllChanges} />
                                                    </MDBCol>

                                                    <MDBCol lg="6" className="py-2">
                                                        <MDBInput label="Latitude" icon="envelope" type="text" error="wrong"
                                                            success="right" name="gpslat" value={formdata.gpslat} onChange={handleAllChanges} />
                                                    </MDBCol>  <MDBCol lg="6" className="py-2">
                                                        <MDBInput label="Longitude" type="text" error="wrong"
                                                            success="right" name="gpslong" value={formdata.gpslong} onChange={handleAllChanges} />
                                                    </MDBCol>
                                                    <MDBCol lg="12" className="py-2">
                                                        <select label="Status" className="browser-default custom-select p-2" style={{ borderRadius: "5px", borderColor: "#BDBDBD", width: "50%" }}
                                                            name='status' onChange={handleAllChanges} value={statusMap[formdata.status]}
                                                        >
                                                            <option value={formdata.status}>{statusMap[formdata.status]}</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>

                                                        </select>
                                                    </MDBCol>

                                                </MDBRow>
                                            </div>


                                            <div className="text-center w-100">
                                                <MDBBtn type={"button"} className="mx-2  my-5" style={{ backgroundColor: "#13538a" }} onClick={() => {
                                                    let allow = true;
                                                    if (!formdata.details) {
                                                        toast.error("Detais not provided.")
                                                        allow = false;
                                                    }
                                                    else if (!formdata.gpslat) {
                                                        toast.error("Latitude not provided.")
                                                        allow = false;
                                                    }

                                                    else if (!formdata.gpslong) {
                                                        toast.error("Longitude not provided.")
                                                        allow = false;
                                                    }
                                                    else if (!formdata.location) {
                                                        toast.error("Location not provided.")
                                                        allow = false;
                                                    }
                                                    else if (formdata.gpslong.match(/[a-zA-Z]/)) {
                                                        toast.error("Provide a valid longitude (numeric value only)");
                                                        allow = false;
                                                    }
                                                    else if (formdata.gpslat.match(/[a-zA-Z]/)) {
                                                        toast.error("Provide a valid latitude (numeric value only)");
                                                        allow = false;
                                                    }

                                                    else if (allow) { updateAtm() }
                                                }}>Update ATM</MDBBtn>
                                            </div>
                                        </form>
                                    </MDBCol>
                                </MDBRow>

                            </MDBCardBody>
                        )
                    }

                </MDBRow>
            </MDBCard>

        </MDBContainer>

    );
}

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-pro-sidebar/dist/css/styles.css';
// import innologo from "../assets/inovative.png"
import '../styles/App.scss';
import {
  MDBDropdown,
  MDBDropdownLink,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBBtn
} from 'mdb-react-ui-kit';
import { BackOfficeManagement } from "./backoffice_portal/BackOfficeManagement"
import {
  BrowserRouter as Router, Routes, Route, Link, useParams,

} from "react-router-dom";
import { getToken, resetToken, getAdminProfile, getAdminToken } from '../reducers/AuthSlice'
import { Navigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
export function Landing() {
  const token = useSelector(getAdminToken);
  const adminProfile = useSelector(getAdminProfile);
  const [shownav, setshownav] = useState(true);

  let location = useLocation();
  let dispatch = useDispatch();
  if (!token) {

    return <Navigate to={{ pathname: '/', state: { from: location } }} />

  }
  return (
    <div style={{ height: "100vh" }} >
      <div className="d-flex" >
        <Sidebar navVal={shownav} setnavVal={setshownav} />
        <div className={shownav ? "content-open" : "content-close"}>
          <div className="custom-sidebar-content-nav">

            <MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className="mt-3 mb-2 mx-1 nav-btns" >  <Link to={"/home/backoffice/ATMList/addATM"} style={{ color: "white" }}>Add ATM</Link></MDBBtn>
            <MDBBtn style={{ backgroundColor: "#7BB1BE", color: "white" }} className="mt-3 mb-2 mx-1 nav-btns" >  <Link to={"/home/backoffice/Userlist/addUser"} style={{ color: "white" }}>Add User</Link></MDBBtn>
            <MDBDropdown className='py-2 px-5' style={{ width: "max-content", display: "inline-block" }}>
              <MDBDropdownToggle tag='a' style={{ color: "#13538a", cursor: 'pointer' }} >
                <b style={{ textTransform: 'capitalize' }}>
                  {adminProfile.name}
                </b>
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <span className='nav-item'>
                  <MDBDropdownItem >
                    <MDBDropdownLink href="#"><Link to={"/home/backoffice/ATMList/addATM"}>Add ATM</Link></MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink href="#"> <Link to={"/home/backoffice/Userlist/addUser"}>Add User</Link></MDBDropdownLink>
                  </MDBDropdownItem>
                </span>

                <MDBDropdownItem>
                  <MDBDropdownLink href="#"><Link to="#" onClick={() => { dispatch(resetToken()) }} >Log Out</Link></MDBDropdownLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </div>
          <div style={{ marginTop: "0px" }}>
            <Routes>
              <Route path={`backoffice/*`} element={<BackOfficeManagement />} />
            </Routes>
          </div>

        </div>

      </div>
      {/* <div style={{ position: "fixed", bottom: 0, right: 0 }}>
        <img
          src={innologo}
          height="30"
          alt=""
          loading="lazy"
        />
      </div> */}
    </div>
  );
}


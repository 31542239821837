import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  Link, useNavigate } from "react-router-dom";

import {

    MDBRow,
    MDBCol,
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBContainer,
    MDBBtn,
    MDBInput


} from 'mdb-react-ui-kit';
import { getToken, loginAsync, getAdminToken } from '../../reducers/AuthSlice'

import { AddNewATMAsync } from '../../reducers/ATMSlice'
import { toast } from 'react-toastify';
export const AddATM = () => {
    const token = useSelector(getAdminToken);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [formdata, setdata] = useState({
        details: "",
        gpslat: "",
        gpslong: "",
        location: ""
    })
    const handleAllChanges = (event) => {
        setdata(
            (PrevDict) => {
                return {
                    ...PrevDict,
                    [event.target.name]: event.target.value
                }
            }
        )
    }
    const addNewATM = () => {
        dispatch(AddNewATMAsync({ formdata, token })).then(() => {
            navigate("/home/backoffice/ATMlist")
        });
    }
    return (
        <MDBContainer className="py-4 text-start ">
            <MDBCard alignment='left' >
                <MDBRow className='px-1'>
                <MDBCol lg='10' md='8' sm='8'>
                        <h3 className='text-start m-3' style={{ color: "#13538a"}}>Add New ATM</h3>
                    </MDBCol>
                    <MDBCol lg='2' md='4' sm='4'>
                        <Link to={"/home/backoffice/ATMlist"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3'> Back</MDBBtn></Link>
                    </MDBCol>
                    <MDBCardBody>
                        <MDBRow className='px-2' >
                            <MDBCol lg='12' className='p-2 text-start' style={{ color: "#1c1c52"}}>
                                <form>
                                    <div className="text-start">
                                        <MDBRow>
                                            <MDBCol lg="12" className="py-2">
                                                <MDBInput label="ATM Details" type="text" error="wrong"
                                                    success="right" name="details" value={formdata.details} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="12" className="py-2">
                                                <MDBInput label="Location" icon="envelope" type="text" error="wrong"
                                                    success="right" name="location" value={formdata.location} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="12" className="py-2">
                                                <MDBInput label="Latitude" type="text" error="wrong"
                                                    success="right" name="gpslat" value={formdata.gpslat} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="12" className="py-2">
                                                <MDBInput label="Longitude" type="text" error="wrong"
                                                    success="right" name="gpslong" value={formdata.gpslong} onChange={handleAllChanges} />
                                            </MDBCol>

                                        </MDBRow>
                                    </div>
                                    <div className="text-center w-100">
                                        <MDBBtn type={"button"} className="mx-2  my-5" style={{ backgroundColor: "#13538a" }} onClick={() => {
                                            let allow = true;
                                            if (!formdata.details) {
                                                toast.error("Details not provided.")
                                                allow = false;
                                            }
                                            if (!formdata.location) {
                                                toast.error("Location not provided.")
                                                allow = false;
                                            }
                                            if (!formdata.gpslat) {
                                                toast.error("Latitude not provided.")
                                                allow = false;
                                            }
                                            if (!formdata.gpslong) {
                                                toast.error("Longitude not provided.")
                                                allow = false;
                                            }
                                            else if (formdata.gpslong.match(/[a-zA-Z]/)) {
                                                toast.error("Provide a valid longitude (numeric value only)");
                                                allow = false;
                                            }
                                            else if (formdata.gpslat.match(/[a-zA-Z]/)) {
                                                toast.error("Provide a valid latitude (numeric value only)");
                                                allow = false;
                                            }
                                            if (allow){addNewATM ()}
                                        }
                                    }>Add ATM</MDBBtn>
                                    </div>
                                </form>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBRow>
            </MDBCard>
        </MDBContainer>
    );
}


import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import {

  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBContainer,
  MDBBtn,
  MDBDropdown, MDBDropdownItem, MDBDropdownToggle,
  MDBDropdownLink,
  MDBDropdownMenu


} from 'mdb-react-ui-kit';
import { GridButton } from '../../CustomComponents/GridButton';
import DataTable from 'react-data-table-component';
import { getToken, loginAsync, getAdminToken } from '../../reducers/AuthSlice'
import { GetCompletedTicketsListAsync, CompletedTicketList, GetAssignedTicketsListAsync,AssignedTicketsList } from '../../reducers/TicketSlice'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const statusMap = { "1": "Active", "2": "Suspended", "3": "Dropped" };
export const CompletedTickets = () => {
  const dispatch = useDispatch();
  const token = useSelector(getAdminToken);
  const [searchInput, setSearchInput] = useState("");
  const [dateToSelect, setDateToSelectInput] = useState(new Date());
  const [dateFromSelect, setDateFromSelecthInput] = useState(new Date());

  const [statusdata, setStatusData] = useState("-1")
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);

  };
  const handleFilter = (e) => {
    e.preventDefault();
    setStatusData(e.target.value);


  };

  const CompletedTicketsdata = useSelector(CompletedTicketList);

  const customStyles = {
    table: {
      style: {
        color: "#13538a",
        backgroundColor: "transparent",
      },
    },

    head: {
      style: {
        color: "white",
        fontSize: '12px',
        fontWeight: 600,
      },
    },
    headRow: {
      style: {
        backgroundColor: "#13538a",
        minHeight: '52px',
        borderBottomWidth: '2px',
        borderBottomColor: "white",
        borderBottomStyle: 'solid',
      },

    },
    rows: {
      style: {
        fontSize: '13px',
        fontWeight: 400,
        color: "black",
        backgroundColor: "transparent",
        minHeight: '48px',
        '&:not(:last-of-type)': {
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: "#13538a",
        },
      },
      // denseStyle: {
      //     minHeight: '32px',
      // },
      stripedStyle: {
        color: "black",
        backgroundColor: "transparent",
      },
    },

    headCells: {
      style: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
      draggingStyle: {
        cursor: 'move',
      },
    },

    pagination: {
      style: {
        color: "#13538a",
        fontSize: '13px',
        minHeight: '56px',
        backgroundColor: "transparent",
        borderTopStyle: 'solid',
        borderTopWidth: '2px',
        borderTopColor: "#13538a"
      },
      pageButtonsStyle: {

        height: '40px',
        width: '40px',
        color: "#13538a",
        fill: "#13538a",
        backgroundColor: 'transparent',

      },
    },
  }
  let CompletedTicketDict = { "0": "Assigned", "2": "Completed", "3": "Carry Forwarded", "4": "Accepted", "5": "transfered" };

  const CompletedTicketsColumns = [
    {
      name: 'Reference Number',
      selector: row => row.referencenum,
    },
    {
      name: 'Ticket ID',
      selector: row => row.ticketid,

    },
    {
      name: 'ATM ID',
      selector: row => row.atmid,
    },
    {
      name: 'Username',
      selector: row => row.username,
    },
    {
      name: 'Distance(Km)',
      selector: row => row.distance,
    },
  ];

  let completedticketstable = CompletedTicketsdata.filter(item => {
    if (searchInput.length == 0 && statusdata == "-1") {
      return item;
    }
    else if (searchInput.length == 0 && statusdata == item.status) {

      return item;
    }
    else if (item.ticketid == searchInput && statusdata == "-1") {

      return item;
    }
    else if (item.atmid == searchInput && statusdata == "-1") {

      return item;
    }
    else if (item.atmid == searchInput && statusdata == item.status) {
      return item;
    }
    else if (item.ticketid == searchInput && statusdata == item.status) {

      return item;
    }
    else if (item.referencenum.toLowerCase().includes(searchInput.toLowerCase()) && statusdata == item.status) {

      return item;
    }
  });

  return (
    <MDBContainer className="py-4 text-start ">

      <MDBCard alignment='left' >

        <MDBRow className='px-1'>
          <MDBCol lg='10' md='8' sm='8'>
            <h3 className='text-start m-3' style={{ color: "#13538a" }}>Completed Tickets</h3>
          </MDBCol>
          <MDBCol lg='2' md='4' sm='4'>
            <Link to={"/home/backoffice/Tickets"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3 px-3 py-2'> Back</MDBBtn></Link>
          </MDBCol>
          <MDBCardBody>
            <MDBRow>
              <MDBCol sm='9' md='9' lg='4' className='py-2' >
                <MDBInput label="Search by ATM ID, ticket ID, or username" icon="serach" type="search" error="wrong"
                  success="right" name="search" value={searchInput} onChange={handleSearch} />
              </MDBCol>
             
            </MDBRow>
            <MDBRow>
    
              <MDBCol sm='4' md='4' lg='4' className='py-2' >
                <label>Date From</label>
                <br/>
                <DatePicker selected={dateFromSelect} onChange={(date) => setDateFromSelecthInput(date)}  />             
              </MDBCol>
              <MDBCol sm='4' md='4' lg='4' className='py-2' >
                <label>Date From</label>
                <br/>
                <DatePicker selected={dateToSelect} onChange={(date) => setDateToSelectInput(date)}  />             
              </MDBCol>
              <MDBCol sm='12' md='4' lg='4' className='py-2 mt-4' >
              <MDBBtn onClick={()=>{
                dispatch(GetCompletedTicketsListAsync({ token,startDate:dateFromSelect.getFullYear()+"-"+(dateFromSelect.getMonth()+1)+"-"+dateFromSelect.getDate(),endDate:dateToSelect.getFullYear()+"-"+(dateToSelect.getMonth()+1)+"-"+dateToSelect.getDate() }));
              }} > Load </MDBBtn>
              <CSVLink data={CompletedTicketsdata} filename={"Assigned Ticket details.csv"}> <MDBBtn> Download CSV </MDBBtn></CSVLink>
              </MDBCol>
         
            </MDBRow>
            <DataTable
              pagination="true"
              columns={CompletedTicketsColumns}
              data={CompletedTicketsdata}
              highlightOnHover
              fixedHeader
              responsive
              pointerOnHover
              striped
              customStyles={customStyles}
            />
          </MDBCardBody>
        </MDBRow>
      </MDBCard>

    </MDBContainer>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useNavigate } from "react-router-dom";

import {

    MDBRow,
    MDBCol,
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBContainer,
    MDBBtn,
    MDBInput,
    MDBBreadcrumb,
    MDBBreadcrumbItem
} from 'mdb-react-ui-kit';

import { getToken, loginAsync, getAdminToken } from '../../reducers/AuthSlice'

import { AddNewUserAsync } from '../../reducers/UserSlice'
import { toast } from 'react-toastify';
export const AddUser = () => {
    const params = useParams()
    const token = useSelector(getAdminToken);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [formdata, setdata] = useState({
        name: "",
        username: "",
        cnic: "",
        city: "",
        details: "",
        email: "",
        homeaddress: "",
        homelat: "",
        homelong: "",
        region: "",
        password: "",
        externalId: ""
    })
    const handleAllChanges = (event) => {
        setdata(
            (PrevDict) => {
                return {
                    ...PrevDict,
                    [event.target.name]: event.target.value
                }
            }
        )
    }
    const addNewUser = () => {
        dispatch(AddNewUserAsync({ formdata, token })).then(() => {
            navigate("/home/backoffice/Userlist")
        });

    }
    return (
        <MDBContainer className="py-4 text-start ">

            <div className='w-100'>
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem>
                        Users
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add user</MDBBreadcrumbItem>
                </MDBBreadcrumb>
            </div>
            <MDBCard alignment='left' style={{ borderColor: "#1c1c52" }} >

                <MDBRow className='px-1'>
                    <MDBCol lg='10' md='8' sm='8'>
                        <h3 className='text-start m-3' style={{ color: "#13538a" }}>Add New User</h3>
                    </MDBCol>

                    <MDBCardBody>
                        <MDBRow className='px-2' >
                            <MDBCol lg='12' className='p-2 text-start' style={{ color: "#1c1c52" }}>
                                <form>
                                    <div className="text-start">
                                        <MDBRow>
                                            <MDBCol lg="6" className="py-2">
                                                <MDBInput label="Name" type="text" error="wrong"
                                                    success="right" name="name" value={formdata.name} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="6" className="py-2">
                                                <MDBInput label="CNIC (without hyphen)" icon="envelope" type="text" error="wrong"
                                                    success="right" name="cnic" value={formdata.cnic} onChange={handleAllChanges} max="13" pattern="^\d{5}-\d{8}-\d{1}$" />
                                            </MDBCol>
                                            <MDBCol lg="12" className="py-2">
                                                <MDBInput label="Username" type="text" error="wrong"
                                                    success="right" name="username" value={formdata.username} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="6" className="py-2">
                                                <MDBInput label="OTRS User ID" type="text" error="wrong"
                                                    success="right" name="externalId" value={formdata.externalId} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="6" className="py-2">
                                                <MDBInput label="Password" type="text" error="wrong"
                                                    success="right" name="password" value={formdata.password} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="6" className="py-2">
                                                <MDBInput label="Email" icon="envelope" type="email" error="wrong"
                                                    success="right" name="email" value={formdata.email} onChange={handleAllChanges} />
                                            </MDBCol>  <MDBCol lg="6" className="py-2">
                                                <MDBInput label="City" type="text" error="wrong"
                                                    success="right" name="city" value={formdata.city} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="12" className="py-2">
                                                <MDBInput label="Details" icon="envelope" type="text" error="wrong"
                                                    success="right" name="details" value={formdata.details} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="12" className="py-2">
                                                <MDBInput label="Address" icon="envelope" type="text" error="wrong"
                                                    success="right" name="homeaddress" value={formdata.homeaddress} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="4" className="py-2">
                                                <MDBInput label="Latitude" type="text" error="wrong"
                                                    success="right" name="homelat" value={formdata.homelat} onChange={handleAllChanges} />
                                            </MDBCol>

                                            <MDBCol lg="4" className="py-2">
                                                <MDBInput label="Longititude" icon="envelope" type="text" error="wrong"
                                                    success="right" name="homelong" value={formdata.homelong} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="4" className="py-2">
                                                <MDBInput label="Region" type="text" error="wrong"
                                                    success="right" name="region" value={formdata.region} onChange={handleAllChanges} />
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                    <div className="d-flex justify-content-end w-100">
                                        <MDBBtn type={"button"} className="mx-2  my-5" style={{ backgroundColor: "#13538a" }} onClick={() => {
                                            let allow = true;
                                            if (!formdata.name) {
                                                toast.error("Name not provided.")
                                                allow = false;
                                            }
                                            else if (!formdata.email) {
                                                toast.error("Email not provided.")
                                                allow = false;
                                            }
                                            else if (!formdata.password) {
                                                toast.error("Password not provided.")
                                                allow = false;
                                            }
                                            else if (!formdata.details) {
                                                toast.error("Details not provided.")
                                                allow = false;
                                            }
                                            else if (!formdata.city) {
                                                toast.error("City not provided.")
                                                allow = false;
                                            }
                                            else if (!formdata.cnic) {
                                                toast.error("CNIC not provided.")
                                                allow = false;
                                            }
                                            else if (!formdata.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                                                toast.error("Provide a valid email")
                                                allow = false;
                                            }

                                            else if (formdata.city.match(/\d/)) {
                                                toast.error("Provide a valid city");
                                                allow = false;
                                            }
                                            else if (formdata.cnic.match(/[a-zA-Z]/)) {
                                                toast.error("Provide a valid cnic");
                                                allow = false;
                                            }
                                            else if (!formdata.homeaddress) {
                                                toast.error("Address not provided.")
                                                allow = false;
                                            }

                                            else if (formdata.cnic.length != 13) {
                                                toast.error("Provide a valid CNIC")
                                                allow = false;
                                            }
                                            else if (!formdata.cnic.match(/^\d{5}\d{7}\d$/)) {
                                                toast.error("Provide a valid CNIC number without hyphens");
                                                allow = false;
                                            }

                                            else if (!formdata.homelat) {
                                                toast.error("Latitude not provided.")
                                                allow = false;
                                            }
                                            else if (formdata.homelat.match(/[a-zA-Z]/)) {
                                                toast.error("Provide a valid Latitude");
                                                allow = false;
                                            }
                                            else if (!formdata.homeaddress) {
                                                toast.error("Address not provided.")
                                                allow = false;
                                            }
                                            else if (!formdata.homelong) {
                                                toast.error("Longitude not provided.")
                                                allow = false;
                                            }
                                            else if (formdata.homelong.match(/[a-zA-Z]/)) {
                                                toast.error("Provide a valid longitude (numeric value only)");
                                                allow = false;
                                            }
                                            else if (formdata.homelat.match(/[a-zA-Z]/)) {
                                                toast.error("Provide a valid latitude (numeric value only)");
                                                allow = false;
                                            }
                                            else if (!formdata.username) {
                                                toast.error("Username not provided.")
                                                allow = false;
                                            }
                                            else if (!formdata.region) {
                                                toast.error("Region not provided.")
                                                allow = false;
                                            }
                                            else if (formdata.region.match(/\d/)) {
                                                toast.error("Provide a valid Region");
                                                allow = false;
                                            }
                                            else if (allow) { addNewUser() }
                                        }}>Add User</MDBBtn>

                                        <Link to={"/home/backoffice/Userlist"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end my-5 mx-2 px-3 py-2'> Back</MDBBtn></Link>

                                    </div>

                                </form>
                            </MDBCol>
                        </MDBRow>

                    </MDBCardBody>
                </MDBRow>
            </MDBCard>

        </MDBContainer>
    );
}

import React from 'react';
import {
  BrowserRouter as Router, Routes, Route
} from "react-router-dom";

import { UserList } from './UserList';
import { ATMList } from './ATMList';
import { CompletedTickets } from './CompleteTickets';
import { AssignedTickets } from './AssignedTickets';
import { Tickets } from './Tickets';
import { UpdateATM } from './UpdateATM';
import { ClaimRequests } from './ClaimRequests';
import UpdateRequest from './UpdateRequest';
import { Dashboard } from './Dashboard';
import { ViewSingleUser } from './ViewSingleUser';
import { ViewAssignedTicket } from './ViewAssignedTicket';
import { ViewClaimRequest } from './ViewClaimRequest';
import { AddUser } from './AddUser';
import { AddATM } from './AddATM';
import { UpdateAdminDetails } from './UpdateAdminDetails';
import { UpdateUser } from './UpdateUser';
import { CreateTicket } from './CreateTicket';
import UpdateTicket from './UpdateTicket';
import {UpdateTicketDetails} from './UpdateTicketDetails'
import { ViewTicket } from './ViewTicket';
export function BackOfficeManagement() {
  return (
    <Routes>
      <Route path={`Userlist/:userid`} element={<ViewSingleUser />} />
      <Route path={`Userlist/update/:userid`} element={<UpdateUser />} />
      <Route path={`updateDetails`} element={<UpdateAdminDetails />} />
      <Route path={`Userlist/addUser`} element={<AddUser />} />
      <Route path={`ATMlist/addATM`} element={<AddATM />} />
      <Route path={`ATMlist/list`} element={<ATMList />} />
      <Route path={`ATMlist/update/:atmid`} element={<UpdateATM />} />
      <Route path={`Userlist`} element={<UserList />} />
      <Route path={`Userlist/list`} element={<UserList />} />
      <Route path={`Dashboard`} element={<Dashboard />} />
      <Route path={`ATMlist`} element={<ATMList/>} />
      <Route path={`Tickets`} element={<Tickets />} />
      <Route path={`Tickets/update/:ticketid`} element={<UpdateTicketDetails />} />
      <Route path={`Tickets/AssignedTickets`} element={<AssignedTickets />} />
      <Route path={`Tickets/CompleteTickets`} element={<CompletedTickets />} />
      <Route exact path={`Tickets/updateticket/:ticketid/:id`} element={<UpdateTicket />} />
      <Route exact path={`Tickets/updateticket/:ticketid`} element={<UpdateTicket />} />
      <Route path={`Tickets/:ticketid`} element={<ViewTicket />} />
      <Route path={`Tickets/create`} element={<CreateTicket/>} />
      <Route path={`Tickets/AssignedTickets/:ticketid/:id`} element={<ViewAssignedTicket />} />
      <Route path={`ClaimRequests`} element={<ClaimRequests />} />
      <Route path={`ClaimRequests/:claimid`} element={<ViewClaimRequest />} />
      <Route path={`ClaimRequests/update/:ticketid`} element={<UpdateRequest />} />
    </Routes>
  );
}

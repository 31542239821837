import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import {

    MDBRow,
    MDBCol,

    MDBCard,
    MDBCardBody,
    MDBContainer,
    MDBBtn,
    MDBInput


} from 'mdb-react-ui-kit';
import { getToken, loginAsync, getAdminToken } from '../../reducers/AuthSlice'

import { CreateNewTicketAsync } from '../../reducers/TicketSlice'
import { GetATMListAsync, GetAllActiveATMAsync, getATMList, getactiveATM } from '../../reducers/ATMSlice'
import { toast } from 'react-toastify';
export const CreateTicket = () => {
    const params = useParams()
    const token = useSelector(getAdminToken);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [selectedOption, setatm] = useState('')
    const [formdata, setdata] = useState({
        atmid: '',
        details: "",
        description: "",
        priority: "",

    })
    useEffect(() => {

        dispatch(GetAllActiveATMAsync({ token }));
    }, []);
    const atmdata = useSelector(getactiveATM)?.data

    const optionList = []
    if (atmdata) {
        atmdata.forEach((val) => {
            optionList.push({ value: val.atmid, label: val.details })
        })
    }

    const handleAllChanges = (event) => {
        setdata(
            (PrevDict) => {
                return {
                    ...PrevDict,
                    [event.target.name]: event.target.value
                }
            }
        )
    }
    const handle = (data) => {
        setatm(data)
        setdata(
            (PrevDict) => {
                return {
                    ...PrevDict,
                    "atmid": data.value
                }
            })
    }
    const atm = atmdata ? atmdata.filter((item) => {
        if (item.atmid == formdata.atmid) {
            return item
        }
    }) : {};
    const CreateNewTicket = () => {
        dispatch(CreateNewTicketAsync({ formdata, token })).then(() => {
            navigate("/home/backoffice/Tickets")
        });
    }
    return (
        <MDBContainer className="py-4 text-start ">

            <MDBCard alignment='center' style={{ borderColor: "#1c1c52" }} >
                <MDBRow>
                    <MDBCol lg='10' >
                        <h3 className='text-start m-3' style={{ color: "#13538a" }}>Create Ticket</h3>
                    </MDBCol>
                    <MDBCol lg='2'>
                        <Link to={"/home/backoffice/Tickets"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3'> Back</MDBBtn></Link>
                    </MDBCol>
                    <MDBCardBody>
                        <MDBRow className='px-2' >
                            <MDBCol lg='12' className='p-2 text-start' style={{ color: "#1c1c52" }}>
                                <form>
                                    <div className="text-start">
                                        <MDBRow>

                                            <MDBCol lg='12' md='12' className="py-2">
                                                <Select
                                                    options={optionList}
                                                    placeholder="Select ATM"
                                                    name='atmid'
                                                    value={selectedOption}
                                                    onChange={handle}
                                                />
                                                {formdata.atmid && <small className='text-dark'>Location: {atm[0].location}</small>}
                                            </MDBCol>
                                            <MDBCol lg='6' md='12' className='py-2'>
                                                <select className="browser-default custom-select p-2" name='priority' style={{ borderRadius: "5px", borderColor: "#BDBDBD", width: "80%" }}
                                                    value={formdata.priority} onChange={handleAllChanges}
                                                >
                                                    <option value="-1">--Select Priority--</option>
                                                    <option value="1">High</option>
                                                    <option value="2">Medium</option>
                                                    <option value="3">Low</option>
                                                </select>
                                            </MDBCol>


                                            <MDBCol lg='12' className="py-2">
                                                <MDBInput label="Description" icon="envelope" type="text" error="wrong"
                                                    success="right" name="description" value={formdata.description} onChange={handleAllChanges} />
                                            </MDBCol>
                                            <MDBCol lg="12" className="py-2">
                                                <MDBInput label="Details" icon="envelope" type="text" error="wrong"
                                                    success="right" name="details" value={formdata.details} onChange={handleAllChanges} />
                                            </MDBCol>

                                        </MDBRow>
                                    </div>
                                    <div className="text-center w-100">
                                        <MDBBtn type={"button"} className="mx-2  my-5" style={{ backgroundColor: "#13538a" }} onClick={() => {
                                            let allow = true;
                                            if (!formdata.atmid) {
                                                toast.error("ATM not provided.")
                                                allow = false;
                                            }
                                            else if (!formdata.details) {
                                                toast.error("Details not provided.")
                                                allow = false;
                                            }
                                            else if (!formdata.description) {
                                                toast.error("Description not provided.")
                                                allow = false;
                                            }
                                            else if (formdata.priority == "-1") {
                                                toast.error("Select priority level of ticket")
                                                allow = false;
                                            }

                                            else if (allow) { CreateNewTicket() }
                                        }}>Create Ticket</MDBBtn>
                                    </div>

                                </form>
                            </MDBCol>
                        </MDBRow>

                    </MDBCardBody>
                </MDBRow>
            </MDBCard>

        </MDBContainer>
    );
}

import React, { useState } from 'react';


import {
  
  MDBCol,
  MDBIcon,
 
  MDBBtn,
  MDBInput
} from 'mdb-react-ui-kit';


export function GridButton(props){

  return (
    <MDBBtn tag='a' color='none'  style={{ color: props.color }} >
      <MDBIcon fas icon={props.iconName} size="lg"  />
    </MDBBtn>
  );
}

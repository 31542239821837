import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from "react-csv";

import {

    MDBRow,
    MDBCol,
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBInput,
    MDBContainer,


} from 'mdb-react-ui-kit';
import { GridButton } from '../../CustomComponents/GridButton';
import DataTable from 'react-data-table-component';
import { getAdminToken } from '../../reducers/AuthSlice'

import { Link } from 'react-router-dom';
import { GetUserListAsync, getUsersList } from "../../reducers/UserSlice"

const statusMap = { "1": "Active", "2": "Suspended", "3": "Dropped" };
export const UserList = () => {
    const dispatch = useDispatch();
    const token = useSelector(getAdminToken);
    useEffect(() => {
        dispatch(GetUserListAsync({ token }));
    }, []);
    const userdata = useSelector(getUsersList);
    const [searchInput, setSearchInput] = useState("");
    const handleSearch = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);

    };
    const customStyles = {
        table: {
            style: {
                color: "#13538a",
                backgroundColor: "transparent",
            },
        },

        head: {
            style: {
                color: "white",
                fontSize: '12px',
                fontWeight: 600,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#13538a",
                minHeight: '52px',
                borderBottomWidth: '2px',
                borderBottomColor: "white",
                borderBottomStyle: 'solid',
            },

        },
        rows: {
            style: {
                fontSize: '13px',
                fontWeight: 400,
                color: "black",
                backgroundColor: "transparent",
                minHeight: '48px',
                '&:not(:last-of-type)': {
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    borderBottomColor: "#13538a",
                },
            },
            // denseStyle: {
            //     minHeight: '32px',
            // },
            stripedStyle: {
                color: "black",
                backgroundColor: "transparent",
            },
        },

        headCells: {
            style: {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
            draggingStyle: {
                cursor: 'move',
            },
        },

        pagination: {
            style: {
                color: "#13538a",
                fontSize: '13px',
                minHeight: '56px',
                backgroundColor: "transparent",
                borderTopStyle: 'solid',
                borderTopWidth: '2px',
                borderTopColor: "#13538a"
            },
            pageButtonsStyle: {

                height: '40px',
                width: '40px',
                paddingLeft: '0px',
                paddingRight: '0px',
                color: "#13538a",
                fill: "#13538a",
                backgroundColor: 'transparent',

            },
        },
    }
    const userColumns = [
        {
            name: 'ID',
            selector: row => <b>{row.id}</b>,
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Username',
            selector: row => row.username,
        },
        {
            name: 'Email',
            selector: row => row.email,
            grow: 2
        },
        {
            name: 'CNIC',
            selector: row => row.cnic,
            grow: 2
        },

        {
            name: 'View',
            selector: row =>
                <Link to={"/home/backoffice/Userlist/" + row.id} title='View details'><GridButton iconName="eye" color="#13538a" /> </Link>

        },
        {
            name: 'Edit',
            selector: row =>
                <Link to={"/home/backoffice/Userlist/update/" + row.id}><GridButton iconName="edit" color="#13538a" /> </Link>

        },
    ];
    const usertabledata = userdata.filter(item => {
        if (searchInput.length == 0) {
            return item;
        } else if (item.name.toLowerCase().includes(searchInput.toLowerCase())) {
            return item;
        }
        else if (item.cnic.includes(searchInput)) {
            return item;
        }
        else if (item.username.toLowerCase().includes(searchInput.toLowerCase())) {
            return item;
        }

    });

    return (
        <div className="" style={{ height: "90vh", backgroundColor: "#fafafa" }}>
            <MDBContainer
                className="p-4"
                style={{ height: "90vh", backgroundColor: "#fafafa" }}
            >
                <MDBRow className="px-1">
                    <MDBCol lg="12">
                        <h3 className="text-start m-3" style={{ color: "#13538a" }}>
                            User Records
                        </h3>
                    </MDBCol>
                </MDBRow>


                <MDBCardBody>
                    <MDBRow>
                        <MDBCol sm='12' md='12' lg='8' className='py-2' >
                            <MDBInput
                                label="Search by CNIC, username, or name"
                                icon="search"
                                type="search"
                                error="wrong"
                                success="right"
                                name="search"
                                value={searchInput}
                                onChange={handleSearch} />
                        </MDBCol>
                        {/* <MDBCol sm='12' md='2' lg='6' className='pt-3 text-end'>
                            <CSVLink data={userdata} filename={"User details.csv"}><span style={{ color: "#13538a", fontSize: "0.7rem" }} >Download (CSV)</span></CSVLink>
                        </MDBCol> */}
                        <MDBCol sm="12" md="12" lg="4" className="py-2 text-end">
                            <CSVLink data={userdata} filename={"User details.csv"}>
                                <span style={{ color: "#13538a", fontSize: "0.7rem" }}>
                                    Download (CSV)
                                </span>
                            </CSVLink>
                        </MDBCol>
                    </MDBRow>
                    <DataTable

                        pagination="true"
                        columns={userColumns}
                        data={usertabledata}
                        highlightOnHover
                        fixedHeader
                        responsive
                        pointerOnHover
                        striped
                        customStyles={customStyles}
                    />
                </MDBCardBody>
            </MDBContainer>
        </div>
    );
}

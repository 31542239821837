
import apiUtil from '../utilities/apiHelper'
export function GetAllATMList(data){

  return apiUtil.getApi('admin/atm/getall',data.token)
}
export function GetAllActiveATM(data){

  return apiUtil.getApi('admin/atm/getactive',data.token)
}
export function GetSpecific(data,token){
  return apiUtil.getApi(`admin/atm/getspecific/${data.atmid}`,token)
}
export function AddNewATM(data,token){

  return apiUtil.postApi('admin/atm/create',token,data)
}
export function update(data,token){

  return apiUtil.postApi(`admin/atm/updateAtmDetails/${data.atmid}`,token,data)
}


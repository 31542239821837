import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useLocation } from "react-router-dom";
import "../../App.css"
import {

  MDBRow,
  MDBCol,
  MDBAccordion, MDBAccordionItem,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBBtn,
  MDBPagination, MDBPaginationItem, MDBPaginationLink,


} from 'mdb-react-ui-kit';
import { ClaimRequestsList, GetClaimRequestCommentsAsync, GetSpecificClaimRequest, GetSpecificRequestAsync, GetRequestComments } from '../../reducers/TicketSlice'
import { getAdminToken } from '../../reducers/AuthSlice'
export const ViewClaimRequest = () => {
  const claimDetails = useSelector(ClaimRequestsList);
  // const location = useLocation()
  // const data = location.state?.data
  const dispatch = useDispatch();
  const params = useParams()
  const token = useSelector(getAdminToken);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 3;

  // const claimData = claimDetails.filter((item) => {
  //   if (item.id == params.claimid) {
  //     return item
  //   }
  // })
  const setData = (pageNumber) => {
    setCurrentPage(pageNumber);

    // Calculate the start and end index for slicing the comments array
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Slice the comments array based on the calculated indices
    const pageComments = comments?.comments.slice(startIndex, endIndex);

    // Set the sliced comments in the state
    setDisplayedComments(pageComments);
  }
  useEffect(() => {
    dispatch(GetSpecificRequestAsync({ params: { claimid: params.claimid }, token }));
    dispatch(GetClaimRequestCommentsAsync({ params: { claimid: params.claimid }, token }));
  }, []);
  const comments = useSelector(GetRequestComments)[0];
  const request = useSelector(GetSpecificClaimRequest)[0];
  const [displayedComments, setDisplayedComments] = useState(comments?.comments.slice(0, pageSize));
  useEffect(() => {
    if (comments) {
      setDisplayedComments(comments?.comments.slice(0, pageSize))
    }
  }, [comments]);
  let pagecount = Math.ceil((comments?.comments.length) / 3)
  const paginationItems = [];
  for (let i = 1; i <= pagecount; i++) {
    paginationItems.push(
      <MDBPaginationItem key={i}>
        <MDBPaginationLink >
          <MDBBtn onClick={() => setData(i)} className='px-2 py-1' color='primary' rippleColor='dark'>{i}</MDBBtn>
        </MDBPaginationLink>
      </MDBPaginationItem>
    );
  }


  let ClaimDict = { "0": "Approval required", "1": "Claim approved", "2": "Claim rejected", "3": "Claim discarded" };

  console.log(request)

  return (
    <MDBContainer className="py-4 text-start ">

      <MDBCard alignment='left' style={{ borderColor: "#13538a" }} >
        <MDBRow className='px-1'>
          <MDBCol lg='10' md='8' sm='8'>
            <h3 className='text-start m-3' style={{ color: "#13538a" }}>View Claim Details</h3>
          </MDBCol>
          <MDBCol lg='2' md='4' sm='4'>
            <Link to={"/home/backoffice/ClaimRequests"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3'> Back</MDBBtn></Link>
          </MDBCol>
          {
            request && (
              <MDBCardBody className='small-text'>
                <MDBRow className='px-2' >
                  <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                    Details of Claim ID: {params.claimid} with Ticket ID: {request?.ticketid}
                  </MDBCol>
                </MDBRow>
                <MDBRow className='m-2' style={{ borderColor: "#13538a" }}>
                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Claimer ID: </b><span>{request?.claimerid}</span>
                  </MDBCol>
                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Name: </b><span>{request?.name}</span>
                  </MDBCol>
                  {/* <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Username: </b><span>{request?.username}</span>
                  </MDBCol> */}
                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Email: </b><span>{request?.email}</span>
                  </MDBCol>
                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Amount: </b><span>Rs {request?.amount}</span>
                  </MDBCol>
                  {/* <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>CNIC: </b><span>{request?.cnic}</span>
                  </MDBCol> */}
                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>City: </b><span>{request?.city}</span>
                  </MDBCol>
                  {/* <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Details: </b><span>{request?.details}</span>
                  </MDBCol> */}
                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Region: </b><span>{request?.region}</span>
                  </MDBCol>
                  {/* <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Home Address: </b><span>{request?.homeaddress}</span>
                  </MDBCol> */}
                  {/* <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>GPS: </b><span>{request?.homelat}, {request?.homelong}</span>
                  </MDBCol> */}
                  {/* <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                  <b style={{ color: "#13538a" }}>Claim Details: </b><span>{data["claimdetails"]}</span>
                </MDBCol>
                <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                  <b style={{ color: "#13538a" }}>Description: </b><span>{data["description"]}</span>
                </MDBCol> */}
                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>ATM Location: </b><span>{request?.atmlocation}</span>
                  </MDBCol>
                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>ATM Details: </b><span>{request?.atmdetails}</span>
                  </MDBCol>
                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Ticket Status: </b><span>{ClaimDict[request?.atmstatus]}</span>
                  </MDBCol>
                  {/* <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Claimer Details: </b><span>{request?.details}</span>
                  </MDBCol> */}
                </MDBRow>
              </MDBCardBody>
            )
          }

        </MDBRow>
      </MDBCard>


      <MDBCard alignment='left' style={{ borderColor: "#13538a" }} className='mt-2' >
        <MDBRow className='px-1 py-3'>

          <MDBCol lg='10' >
            <span className='text-start m-3' style={{ color: "black", fontWeight: 'bold' }}>View Comments</span>
          </MDBCol>
          <MDBCardBody className='small-text'>
            {/* <MDBRow className='px-2' >
              {
                request && (
                  <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                    Comments of Claim ID: {params.claimid} with Ticket ID: {request?.ticketid}
                  </MDBCol>
                )
              }

            </MDBRow> */}
            <MDBRow className='m-2' style={{ borderColor: "#13538a" }}>
              {
                (displayedComments?.length > 0) ?
                  (
                    displayedComments.map((val) => {
                      let date = new Date(val["createdAt"])

                      return (
                        <MDBCol lg='8'>
                          <MDBCard style={{ backgroundColor: "", border: '0.5px solid gray' }} className='mt-2'>

                            <MDBRow className='me-1 ms-1 mt-1'>
                              <MDBCol className='col-lg-8 col-md-8 col-sm-12' >
                                <p className='text-start text-primary fw-bold text-capitalize' >{val["name"]}</p>
                              </MDBCol>
                              <MDBCol className='col-lg-4 col-md-4 col-sm-12'>
                                <small className='text-primary fw-bold' >{date.toLocaleDateString()}</small>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className='me-2 ms-2 mb-2'>
                              <p className='text-justify text-start text-dark p-1 text-capitalize' >{val["comment"]}</p>
                            </MDBRow>

                            <MDBRow className='me-0 ms-0 mb-2'>
                              <MDBCol className='col-12 text-start p-0'>
                                <span className="px-3 py-1" style={{ fontSize: "12px" }}>{val["action"]}</span>
                              </MDBCol>
                              {/* <MDBCol className='col-10'>

                              </MDBCol> */}
                            </MDBRow>
                          </MDBCard>

                        </MDBCol>

                      )
                    })
                  ) :
                  (<h4 className='text-center'>No comments to show</h4>
                  )
              }

            </MDBRow>
            {
              paginationItems.length > 0 &&
              <nav aria-label='Page navigation example'>
                <MDBPagination className='mb-0'>
                  {paginationItems}
                </MDBPagination>
              </nav>
            }

          </MDBCardBody>
        </MDBRow>
      </MDBCard>
    </MDBContainer>
  );
}

import axios from "axios";


export default new class HelperApi {

    // baseUrL = "http://localhost:3100/";
    baseUrL = "https://admin-api.innovative-pk.com/"
    async getApi(url, token, params) {

        var queryParams = ""
        if (params) {
            Object.keys(params).forEach((val) => {
                queryParams += val + "=" + params[val] + '&';
            });
        }
        var fullUrl = this.baseUrL + url + "?" + queryParams;
        var resp = await axios.get(fullUrl, {
            headers: {
                'Authorization': "Bearer " + token
            },
        })

        return resp;
    }

    async postApi(url, token, body) {

        var fullUrl = this.baseUrL + url;
        var resp = await axios.post(fullUrl, body, {
            headers: {
                'Authorization': "Bearer " + token
            },
        })

        return resp;
    }

}()


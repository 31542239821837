import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import '../styles/App.scss';
import {

    MDBIcon,
    MDBRow,
    MDBCol,
    MDBBtn

} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import tmslogo from "../assets/tmslogo1.png"
export default function Sidebar(props) {
    let location = useLocation();
    const [atmOptions, setAtmOptions] = useState(false)
    const [ticketOptions, setTicketOptions] = useState(false)
    const [userOptions, setUserOptions] = useState(false)
    const toggleNav = () => props.setnavVal(!props.navVal);
    const handleTicketSidebar = () => setTicketOptions(!ticketOptions)
    const handleUserSidebar = () => setUserOptions(!userOptions)
    const handleAtmSidebar = () => setAtmOptions(!atmOptions)
    const [claimOptions, setClaimOptions] = useState(false)
    const handleClaimSidebar = () => setClaimOptions(!claimOptions)



    return (
        <>
            <div className={props.navVal ? "custom-sidebar-open" : "custom-sidebar-close"} style={{ overflow: 'auto', height: '100vh' }}>
                <div className='w-100'>
                    <MDBBtn outline className='mt-3' color='white' onClick={toggleNav} >
                        <MDBIcon fas icon={props.navVal ? "angle-double-left" : 'angle-double-right'} size='2x' />
                    </MDBBtn>
                    <hr />
                </div>
                <div className="custom-sidebar-logo " >
                    <img src={tmslogo} style={{ width: "3.9rem" }} className={`${!props.navVal ? "logo-small" : "logo"}`} />
                    {props.navVal ? <div style={{ fontSize: "0.9rem" }} className='logo-txt'>Travel Management System</div> : ""}
                </div>
                {/*<div className="custom-sidebar-menu-item active " >
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname.includes("/home/backoffice/dashboard") ? "custom-sidebar-menu-item-inner-active" : "")} style={{ textAlign: props.navVal ? "center" : "center" }}>
                        <Link to={`/home/backoffice/dashboard`} >
                            <div className='sidebaritem d-flex flex-wrap align-items-center justify-content-center'>
                                <MDBRow className='w-100 d-flex' >
                                    <MDBCol lg={`${props.navVal ? "4" : "12"}`} md={`${props.navVal ? "12" : "12"}`} className={`${props.navVal ? "sub-icon-open" : "sub-icon-close"}`}>
                                        <MDBIcon fas icon="tachometer-alt" size='2x' />
                                    </MDBCol>
                                    <MDBCol lg={`${props.navVal ? "8" : "12"}`} md={`${props.navVal ? "12" : "12"}`} className={`${props.navVal ? "sub-text-open" : "sub-text-close"} d-flex align-items-center`}>
                                        {props.navVal ? <span style={{ fontSize: "0.9rem" }} className={`${props.navVal ? "sub-text-open" : "sub-text-close"} pt-1 align-items-center menu-text text-center`} title='Dashboard'>Dashboard</span> : ""}
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </Link>
                    </div>
    </div>*/}
                <div className="custom-sidebar-menu-item active " >
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname == "/home/backoffice/userlist" ? "custom-sidebar-menu-item-inner-active" : "")} style={{ textAlign: props.navVal ? "left" : "center" }} onClick={handleUserSidebar}>
                        <Link to={`/home/backoffice/userlist`} >
                            <div className='sidebaritem d-flex flex-wrap align-items-center justify-content-center'>
                                <MDBRow className='w-100 d-flex' >
                                    <MDBCol lg={`${props.navVal ? "4" : "12"}`} md={`${props.navVal ? "12" : "12"}`} className={`${props.navVal ? "sub-icon-open" : "sub-icon-close"}`}>
                                        <MDBIcon fas icon="users" size='2x' className='mt-2' />
                                    </MDBCol>
                                    <MDBCol lg={`${props.navVal ? "8" : "12"}`} md={`${props.navVal ? "12" : "12"}`} className={`${props.navVal ? "sub-text-open" : "sub-text-close"} d-flex align-items-center`} >
                                        {props.navVal ? <span className={`${props.navVal ? "sub-text-open" : "sub-text-close"} pt-1 align-items-center menu-text text-center`} title='User Management' style={{ fontSize: "0.9rem" }} ><span >
                                            User Management
                                        </span>
                                            &nbsp;{userOptions ? <MDBIcon fas icon="caret-down" className='align-self-center' /> : <MDBIcon className='align-self-center' fas icon="caret-right" />}</span>
                                            : ""}
                                    </MDBCol>
                                </MDBRow>

                                {/* {props.navVal ? <span className='menu-text text-center' title='User Management' style={{ fontSize: "0.9rem", marginLeft: "10px" }} >User Management{userOptions ? <MDBIcon fas icon="caret-down" className='mx-1 align-self-center' /> : <MDBIcon className='mx-1 align-self-center' fas icon="caret-right" />}</span> : ""} */}
                            </div>
                        </Link>
                    </div>
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname.includes("/home/backoffice/Userlist/list") ? "custom-sidebar-menu-item-inner-active" : "")} style={{ display: userOptions ? "block" : "none" }}>
                        
                        <Link to={`/home/backoffice/Userlist/list`} >
                            <div className='d-flex flex-wrap align-items-center justify-content-center custom-sidebar-menu-item-inner-option'>

                                <span className='text-center' style={{ fontSize: "0.8rem" }} ><MDBIcon fas icon="th-list" className="submenu-icon" /> User list</span>
                            </div>
                        </Link>
                    </div>
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname.includes("/home/backoffice/Userlist/addUser") ? "custom-sidebar-menu-item-inner-active" : "")} style={{ display: userOptions ? "block" : "none" }}>
                        
                        <Link to={`/home/backoffice/Userlist/addUser`} >
                            <div className='d-flex flex-wrap align-items-center justify-content-center custom-sidebar-menu-item-inner-option'>

                                <span className='text-center' style={{ fontSize: "0.8rem" }} ><MDBIcon fas icon="user-plus" className="submenu-icon" /> Add User</span>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="custom-sidebar-menu-item active " >
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname == ("/home/backoffice/ATMlist") ? "custom-sidebar-menu-item-inner-active" : "")} style={{ textAlign: props.navVal ? "left" : "center" }} onClick={handleAtmSidebar} >
                        <Link to={`/home/backoffice/ATMlist`} >
                            <div className=' sidebaritem d-flex flex-wrap align-items-center justify-content-center'>
                                <MDBRow className='w-100 d-flex' >
                                    <MDBCol lg={`${props.navVal ? "4" : "12"}`} md={`${props.navVal ? "12" : "12"}`} className={`${props.navVal ? "sub-icon-open" : "sub-icon-close"}`}>
                                        <MDBIcon fas icon="building" size='2x' className='mt-2 me-1' />
                                    </MDBCol>
                                    <MDBCol className={`${props.navVal ? "sub-text-open" : "sub-text-close"} d-flex align-items-center`}>
                                        {props.navVal ? <span className={`${props.navVal ? "sub-text-open" : "sub-text-close"} pt-1 align-items-center menu-text text-center`} title='ATM Management' style={{ fontSize: "0.9rem" }} >
                                            <span >
                                                ATM Management
                                            </span>
                                            &nbsp; {atmOptions ? <MDBIcon fas icon="caret-down" className='align-self-center' /> : <MDBIcon fas icon="caret-right" className='align-self-center' />}
                                        </span> : ""}
                                    </MDBCol>
                                </MDBRow>
                                {/* <MDBIcon fas icon="building" size='2x' style={{ marginRight: "10px" }} />
                                {props.navVal ? <span className='menu-text text-center' title='ATM Management' style={{ fontSize: "0.9rem", marginLeft: "10px" }} >
                                    <span>
                                        ATM Management
                                    </span>
                                    &nbsp; {atmOptions ? <MDBIcon fas icon="caret-down" className='mx-1 align-self-center' /> : <MDBIcon fas icon="caret-right" className='mx-1 align-self-center' />}
                                </span> : ""} */}
                            </div>
                        </Link>
                    </div>
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname.includes("/home/backoffice/ATMList/list") ? "custom-sidebar-menu-item-inner-active" : "")} style={{ display: atmOptions ? "block" : "none" }}>
                        <Link to={`/home/backoffice/ATMList/list`} >
                            <div className='d-flex flex-wrap align-items-center justify-content-center custom-sidebar-menu-item-inner-option'>

                                <span className='text-center' style={{ fontSize: "0.8rem" }} ><MDBIcon fas icon="th-list" className="submenu-icon" /> ATM list</span>
                            </div>
                        </Link>
                    </div>
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname.includes("/home/backoffice/ATMList/addATM") ? "custom-sidebar-menu-item-inner-active" : "")} style={{ display: atmOptions ? "block" : "none" }}>
                        <Link to={`/home/backoffice/ATMList/addATM`} >
                            <div className='d-flex flex-wrap align-items-center justify-content-center custom-sidebar-menu-item-inner-option'>

                                <span className='text-center' style={{ fontSize: "0.8rem" }} ><MDBIcon fas icon="plus" className="submenu-icon" /> Add ATM</span>
                            </div>
                        </Link>
                    </div>
                </div>


                <div className="custom-sidebar-menu-item active" >
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname == "/home/backoffice/Tickets" ? "custom-sidebar-menu-item-inner-active" : "")} style={{ textAlign: props.navVal ? "left" : "center" }} onClick={handleTicketSidebar}>
                        <Link to={`/home/backoffice/Tickets`} >
                            <div className='d-flex flex-wrap align-items-center justify-content-center sidebaritem'>
                                <MDBRow className='w-100 d-flex' >
                                    <MDBCol lg={`${props.navVal ? "4" : "12"}`} md={`${props.navVal ? "12" : "12"}`} className={`${props.navVal ? "sub-icon-open" : "sub-icon-close"}`} >
                                        <MDBIcon fas icon="ticket-alt" size='2x' className='mt-2' />
                                    </MDBCol>
                                    <MDBCol lg={`${props.navVal ? "8" : "12"}`} className={`${props.navVal ? "sub-text-open" : "sub-text-close"} d-flex align-items-center`}>
                                        {props.navVal ?
                                            <span className={`${props.navVal ? "sub-text-open" : "sub-text-close"} pt-1 align-items-center menu-text text-center`} title='Ticket management' style={{ fontSize: "0.9rem" }} >Ticket management&nbsp;{ticketOptions ? <MDBIcon fas icon="caret-down" className='align-self-center' /> : <MDBIcon fas icon="caret-right" className='align-self-center' />}</span> : ""}
                                    </MDBCol>
                                </MDBRow>

                            </div>
                        </Link>
                    </div>
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname.includes("/home/backoffice/Tickets/create") ? "custom-sidebar-menu-item-inner-active" : "")} style={{ display: ticketOptions ? "block" : "none" }}>
                        <Link to={`/home/backoffice/Tickets/create`} >
                            <div className='d-flex flex-wrap align-items-center justify-content-center custom-sidebar-menu-item-inner-option'>

                                <span className='text-center' style={{ fontSize: "0.7rem" }} ><MDBIcon fas icon="pen" className="submenu-icon" /> Create Ticket</span>
                            </div>
                        </Link>
                    </div>
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname.includes("/home/backoffice/Tickets/AssignedTickets") ? "custom-sidebar-menu-item-inner-active" : "")} style={{ display: ticketOptions ? "block" : "none" }}>
                        <Link to={`/home/backoffice/Tickets/AssignedTickets`} >
                            <div className='d-flex flex-wrap align-items-center justify-content-center custom-sidebar-menu-item-inner-option'>

                                <span className='text-center' style={{ fontSize: "0.7rem" }} ><MDBIcon fas icon="user-tag" className="submenu-icon" /> Assigned Tickets</span>
                            </div>
                        </Link>
                    </div>
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname.includes("/home/backoffice/Tickets/CompleteTickets") ? "custom-sidebar-menu-item-inner-active" : "")} style={{ display: ticketOptions ? "block" : "none" }}>
                        <Link to={`/home/backoffice/Tickets/CompleteTickets`} >
                            <div className='d-flex flex-wrap align-items-center justify-content-center custom-sidebar-menu-item-inner-option'>

                                <span className='text-center' style={{ fontSize: "0.7rem" }} ><MDBIcon fas icon="check-circle" className="submenu-icon" /> Completed Tickets</span>
                            </div>
                        </Link>
                    </div>

                </div>

                <div className="custom-sidebar-menu-item active " >
                    <div className={"custom-sidebar-menu-item-inner " + (location && location.pathname == ("/home/backoffice/ClaimRequests") ? "custom-sidebar-menu-item-inner-active" : "")} style={{ textAlign: props.navVal ? "left" : "center" }} onClick={handleClaimSidebar}>
                        <Link to={`/home/backoffice/ClaimRequests`} >
                            <div className='sidebaritem d-flex flex-wrap align-items-center justify-content-center'>
                                <MDBRow className='w-100 d-flex' >
                                    <MDBCol lg={`${props.navVal ? "4" : "12"}`} md={`${props.navVal ? "12" : "12"}`} className={`${props.navVal ? "sub-icon-open" : "sub-icon-close"}`}>
                                        <MDBIcon fas icon="money-check-alt" className='menu-icon mt-2' />
                                    </MDBCol>
                                    <MDBCol className={`${props.navVal ? "sub-text-open" : "sub-text-close"} d-flex align-items-center`} lg={`${props.navVal ? "8" : "12"}`}>
                                        {props.navVal ?
                                            <span className={`${props.navVal ? "sub-text-open" : "sub-text-close"} pt-1 align-items-center menu-text text-center`} title='Claim management' style={{ fontSize: "0.9rem" }} ><span >
                                                Claim management
                                            </span>
                                                &nbsp;
                                            </span> : ""}
                                        {/* {claimOptions ?
                                                <MDBIcon fas icon="caret-down" className='mx-1 align-self-center' /> : <MDBIcon className='mx-1 align-self-center' fas icon="caret-right" />} */}

                                    </MDBCol>
                                </MDBRow>

                                {/* {props.navVal ? <span className='menu-text text-center' title='User Management' style={{ fontSize: "0.9rem", marginLeft: "10px" }} >User Management{userOptions ? <MDBIcon fas icon="caret-down" className='mx-1 align-self-center' /> : <MDBIcon className='mx-1 align-self-center' fas icon="caret-right" />}</span> : ""} */}
                            </div>
                        </Link>
                    </div>

                </div>

            </div>

        </>
    );
}
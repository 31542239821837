
import { useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useParams, Link } from "react-router-dom";
import {

  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBBtn


} from 'mdb-react-ui-kit';

import { getUsersList, GetSingleUserAsync, getSpecificUser } from "../../reducers/UserSlice"
import { getAdminToken } from '../../reducers/AuthSlice'
export const ViewSingleUser = () => {
  // const userDetails = useSelector(getUsersList);
  const params = useParams()
  const dispatch = useDispatch();
  const token = useSelector(getAdminToken);
  // const userData = userDetails.filter((item) => {
  //   if (item.id == params.userid) {
  //     return item
  //   }
  // })
  useEffect(() => {

    dispatch(GetSingleUserAsync({ params: { userid: params.userid }, token }));
  }, []);
  const user= useSelector(getSpecificUser);

  return (
   
      
        <MDBContainer className="py-4 text-start ">
      <MDBCard alignment='left' style={{ borderColor: "#13538a" }} >
        <MDBRow className='px-1'>
          <MDBCol lg='10' md='8' sm='8'>
            <h3 className='text-start m-3' style={{ color: "#13538a" }}>View User Details</h3>
          </MDBCol>
          <MDBCol lg='2' md='4' sm='4'>
            <Link to={"/home/backoffice/Userlist"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3 px-3 py-2'> Back</MDBBtn></Link>
          </MDBCol>
          <MDBCardBody>
            <MDBRow className='px-2' >
              <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                Details of User ID: {params.userid}
              </MDBCol>
            </MDBRow>
            {user && (
            <MDBRow className='m-2' style={{ borderColor: "#13538a" }}>
              <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                <b style={{ color: "#13538a" }}>Name: </b><span>{user?.name}</span>
              </MDBCol>
              <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                <b style={{ color: "#13538a" }}>Username: </b><span>{user?.username}</span>
              </MDBCol>
              <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                <b style={{ color: "#13538a" }}>Email: </b><span>{user?.email}</span>
              </MDBCol>
              <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                <b style={{ color: "#13538a" }}>CNIC: </b><span>{user?.cnic}</span>
              </MDBCol>
              <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                <b style={{ color: "#13538a" }}>City: </b><span>{user?.city}</span>
              </MDBCol>
              <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                <b style={{ color: "#13538a" }}>Details: </b><span>{user?.details}</span>
              </MDBCol>
              <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                <b style={{ color: "#13538a" }}>Region: </b><span>{user?.region}</span>
              </MDBCol>
              <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                <b style={{ color: "#13538a" }}>Home Address: </b><span>{user?.homeaddress}</span>
              </MDBCol>
              <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                <b style={{ color: "#13538a" }}>Latitude: </b><span>{user?.homelat}</span>
              </MDBCol>
              <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                <b style={{ color: "#13538a" }}>Longitude: </b><span>{user?.homelong}</span>
              </MDBCol>
            </MDBRow>)}
          </MDBCardBody>
        </MDBRow>
      </MDBCard>
    </MDBContainer>

   
      );
}

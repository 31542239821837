import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import {
  
  MDBContainer,
  MDBFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBInput
} from 'mdb-react-ui-kit';
import { Footer } from '../Footer';
import bg from "../../assets/bg.jpg"
import { getToken, AdminLoginAsync, getAdminProfile, getAdminToken } from '../../reducers/AuthSlice'
import tmslogo from '../../assets/tmslogo_trans.png'
import loginbg from "../../assets/loginbg1.svg"

export function Login() {
  const token = useSelector(getAdminToken);
  const dispatch = useDispatch();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  let navigate = useNavigate()
  let location = useLocation()


  if (token) {

    return <Navigate to={{ pathname: '/home/backoffice/userlist', state: { from: location } }} />

  }

  return (

    <div style={{ backgroundColor: "white", maxHeight: "100%" }}>
      {/* <MDBNavbar expand='lg' light bgColor='white'>
        <MDBContainer fluid>

          <div className='collapse navbar-collapse w-100' id='navbarExample01' >
            <MDBNavbarNav left className='mb-2 mb-lg-0'>
              <MDBNavbarItem >
                <MDBNavbarLink href='#' className='text-dark'>
                  <h5>Travel Management System</h5>
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
            <MDBNavbarNav right className='mb-2 mb-lg-0 text=right d-flex justify-content-end' >
              <MDBNavbarLink href='#'>

              </MDBNavbarLink>
            </MDBNavbarNav>
          </div>
        </MDBContainer>
      </MDBNavbar> */}
      {/* <MDBRow>
        <MDBCol lg='12'>
          <MDBNavbar expand='lg' light bgColor='white'>
            <MDBContainer fluid>

              <div className='collapse navbar-collapse w-100' id='navbarExample01' >
                <MDBNavbarNav left className='mb-2 mb-lg-0'>
                  <MDBNavbarItem >
                    <MDBNavbarLink href='#' className='text-dark'>
                      <h5>Travel Management System</h5>
                    </MDBNavbarLink>
                  </MDBNavbarItem>
                </MDBNavbarNav>
                <MDBNavbarNav right className='mb-2 mb-lg-0 text=right d-flex justify-content-end' >
                  <MDBNavbarLink href='#'>

                  </MDBNavbarLink>
                </MDBNavbarNav>
              </div>
            </MDBContainer>
          </MDBNavbar>
        </MDBCol>
      </MDBRow> */}
      <MDBRow style={{ height: "100vh" }} >
        <MDBCol lg='7' className='loginImage'>
          <div
            className='p-5 text-center bg-image d-flex justify-content-center align-items-center flex-column'
            style={{ backgroundImage: `url(${loginbg})`, height: "100%" }}
          >
            <img src={tmslogo} style={{ width: "19rem", marginLeft: "10rem" }} />
            {/* <h1 style={{ marginLeft: "10rem", color: "white" }}>Travel Management System</h1> */}
          </div>
        </MDBCol>
        <MDBCol lg='5' className='d-flex justify-content-center align-items-center text-center'>
          <div >
            <MDBContainer>
              <MDBCard style={{ minWidth: '25rem' }} >
                <MDBCardBody>
                  <MDBCardTitle style={{ color: "#13538a" }} ><b>Login to your Account</b></MDBCardTitle>
                  <MDBInput label="Username" className="mt-5 mb-5 text-primary" icon="envelope" group type="email" validate error="wrong"
                    success="right" value={username} onChange={(e) => {
                      setUserName(e.target.value)
                    }} />
                  <MDBInput label="Password" className="mt-5" icon="envelope" group type="password" validate error="wrong"
                    success="right" value={password} onChange={(e) => {
                      setPassword(e.target.value)
                    }} />
                  <MDBBtn className='mt-5 w-100' style={{ backgroundColor: '#13538a' }} onClick={() => dispatch(AdminLoginAsync({
                    "username": username,
                    "password": password
                  }))} >Login</MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBContainer>

          </div>
        </MDBCol>
      </MDBRow>
      {/* <div
        className='p-5 text-center bg-image'
        style={{ backgroundImage: `url(${bg})`, height: "85vh" }}
      >
        <MDBRow>
             <MDBCol lg='12' md='12' style={{ color: "white" }}>
              <h1>TRAVEL MANAGEMENT SYSTEM</h1>
            </MDBCol>
          </MDBRow>
        <MDBContainer className='d-flex justify-content-center align-items-center' >
          
          <MDBRow style={{ minHeight: "60vh" }}>
            <MDBCol lg='12'>
              <div className='d-flex justify-content-center align-items-center h-100'>

                <MDBCard style={{ minWidth: '25rem' }} >
                  <MDBCardBody>
                    <MDBCardTitle style={{ color:"#1c1c52"}} >Management Login</MDBCardTitle>
                    <MDBInput label="Username" className="mt-5 mb-5" icon="envelope" group type="email" validate error="wrong"
                      success="right" value={username} onChange={(e) => {
                        setUserName(e.target.value)
                      }} />
                    <MDBInput label="Password" className="mt-5" icon="envelope" group type="password" validate error="wrong"
                      success="right" value={password} onChange={(e) => {
                        setPassword(e.target.value)
                      }} />

                  
                    <MDBBtn  className='mt-5' style={{ backgroundColor: '#1c1c52' }}  onClick={() => dispatch(AdminLoginAsync({
                      "username": username,
                      "password": password
                    }))} >Login</MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        {/* <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}> */}

      {/* </div> */}
      {/* </div> */}
      {/* <Footer /> */}
    </div>
  );
}

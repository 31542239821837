import React from 'react';


import {
  MDBContainer
} from 'mdb-react-ui-kit';


export const UpdateAdminDetails = () => {

  return (
    <MDBContainer className="py-4 text-start ">
        <h3> Update Your Information</h3>

    </MDBContainer>
  );
}

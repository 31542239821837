import apiUtil from '../utilities/apiHelper'


export function GetUsersList(data){
  return apiUtil.getApi('admin/user/list',data.token)
}
export function AddNewUser(data,token){
  return apiUtil.postApi('admin/user/register',token,data)
}
export function UpdateUser(data,token){

  return apiUtil.postApi('admin/user/update',token,data)
}

export function GetSingleUser(data,token){

  return apiUtil.getApi(`admin/user/${data.userid}`,token)
}




import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { GetTickets,GetAssignedTickets,GetSpecificRequest,GetClaimRequest,GetClaimRequestComments,updateticketDetails,CreateTicket,RejectRequest,GetAssignComments,GetComments,GetSpecificTicket,ClaimedRequest, GetSpecificAssignTicket,AssignTicket,TransferTicket,DiscardTicket, GetCompletedTickets} from "../api/TicketApis"

const initialState = {
  Tickets: [],
  SingleTicket:[],
  ClaimRequests:[],
  SingleRequest:[],
  AssignedTickets:[],
  CompletedTickets: [],
  SingleAssignTicket:[],
  RequestComments:[], 
  AssignTicketComments:[],
  TicketComments:[],
  status: 'idle',
  screenMode: 'list'
};


export const GetTicketsListAsync = createAsyncThunk(
  'ticketDetails/TicketsList',
  async (data) => {
    const response = await GetTickets(data);
    return response.data;
  }
);
export const GetSpecificAssignedTicketAsync = createAsyncThunk(
  'ticketDetails/getspecificassign',
  async (data) => {
    const response = await GetSpecificAssignTicket(data.params,data.token);
    
    return response.data;
  }
);
export const CreateNewTicketAsync = createAsyncThunk(
  'ticketDetails/newTicket',
  async (data) => {
    console.log(data.formdata)
    const response = await CreateTicket(data.formdata,data.token);
    return response.data;
  }
);
export const UpdateTicketAsync = createAsyncThunk(
  'ticketDetails/ypdate',
  async (data) => {
    const response = await updateticketDetails(data.formdata,data.token);
    return response.data;
  }
);
export const SetRequestAsClaimedAsync = createAsyncThunk(
  'ticketDetails/setrequestclaimed',
  async (data) => {
   
    const response = await ClaimedRequest(data.formdata,data.token);
    return response.data;
  }
);
export const SetRequestAsRejectedAsync = createAsyncThunk(
  'ticketDetails/setrequestreject',
  async (data) => {
   
    const response = await RejectRequest(data.rejectdata,data.token);
    return response.data;
  }
);
export const GetClaimRequestCommentsAsync = createAsyncThunk(
  'ticketDetails/getreqcomments',
  async (data) => {
  
    const response = await GetClaimRequestComments(data.params,data.token);
   
    return response.data;
  }
);



export const AssignTicketAsync = createAsyncThunk(
  'ticketDetails/assignTicket',
  async (data) => {
   
    const response = await AssignTicket(data.assigndata,data.token);
    return response.data;
  }
);
export const TransferTicketAsync = createAsyncThunk(
  'ticketDetails/transferTicket',
  async (data) => {
    const response = await TransferTicket(data.formdata,data.token);
    return response.data;
  }
);
export const DiscardTicketAsync = createAsyncThunk(
  'ticketDetails/discardTicket',
  async (data) => {
    
    const response = await DiscardTicket(data.discarddata,data.token);
    return response.data;
  }
);


export const GetAssignedTicketsListAsync = createAsyncThunk(
    'ticketDetails/AssignedTicketsList',
    async (data) => {
      
      const response = await GetAssignedTickets(data);
      
      return response.data;
    }
  );

  export const GetCompletedTicketsListAsync = createAsyncThunk(
    'ticketDetails/CompletedTicketsList',
    async (data) => {
      
      const response = await GetCompletedTickets(data);
      
      return response.data;
    }
  );

  export const GetClaimRequestListAsync = createAsyncThunk(
    'ticketDetails/ClaimedTicketsList',
    async (data) => {
      const response = await GetClaimRequest(data);
      return response.data;
    }
  );
  export const GetAssignTicketCommentsAsync = createAsyncThunk(
    'ticketDetails/assigncomments',
    async (data) => {
     
      const response = await GetAssignComments(data.params,data.token);
     
      return response.data;
    }
  );
  
  export const GetTicketCommentsAsync = createAsyncThunk(
    'ticketDetails/ticketcomments',
    async (data) => {
    
      const response = await GetComments(data.params,data.token);
   
      return response.data;
    }
  );
  
  export const GetSpecificTicketAsync = createAsyncThunk(
    'ticketDetails/getspecific',
    async (data) => {
     
      const response = await GetSpecificTicket(data.params,data.token);
      
      return response.data;
    }
  );
  export const GetSpecificRequestAsync = createAsyncThunk(
    'ticketDetails/getrequest',
    async (data) => {
     
      const response = await GetSpecificRequest(data.params,data.token);
      
      return response.data;
    }
  );
  
export const TicketSlice = createSlice({
  name: 'ticketDetails',
  initialState,
  /*  reducers: {
      changeScreen: (state, action) => {
       // state.screen = action.payload
      },
  
    },*/
  extraReducers: (builder) => {
    builder
      .addCase(GetTicketsListAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetTicketsListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.Tickets = action.payload.list
      })
      .addCase(GetClaimRequestCommentsAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetClaimRequestCommentsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.RequestComments = action.payload.list
      })
     
      .addCase(GetTicketCommentsAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetTicketCommentsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.TicketComments = action.payload.data
      })
      .addCase(GetSpecificRequestAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetSpecificRequestAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.SingleRequest = action.payload.list
      })
     
      .addCase(UpdateTicketAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(UpdateTicketAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success(action.payload.message)
        // state.profileData = action.payload.token;
      })
      .addCase(SetRequestAsRejectedAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(SetRequestAsRejectedAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success(action.payload.message)
        // state.profileData = action.payload.token;
      })
      .addCase(GetSpecificTicketAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetSpecificTicketAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.SingleTicket = action.payload.list
        // state.profileData = action.payload.token;
      })
      .addCase(GetAssignedTicketsListAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetAssignedTicketsListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.AssignedTickets = action.payload.list
        
        // state.profileData = action.payload.token;
      })
      .addCase(GetCompletedTicketsListAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetCompletedTicketsListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.CompletedTickets = action.payload.data
        // state.profileData = action.payload.token;
      })
      .addCase(GetClaimRequestListAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetClaimRequestListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.ClaimRequests = action.payload.list
       
      })
      .addCase(CreateNewTicketAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(CreateNewTicketAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success(action.payload.message)
       
      })
      .addCase(GetSpecificAssignedTicketAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetSpecificAssignedTicketAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.SingleAssignTicket = action.payload.data
        // state.profileData = action.payload.token;
      })
      .addCase(GetAssignTicketCommentsAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(GetAssignTicketCommentsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.AssignTicketComments = action.payload.data
        // state.profileData = action.payload.token;
      })
      .addCase(AssignTicketAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(AssignTicketAsync.fulfilled, (state, action) => {
        state.status = 'idle';
       
        toast.success(action.payload.message)
       
      })
      .addCase(DiscardTicketAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(DiscardTicketAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success(action.payload.message)
       
      })
      .addCase(TransferTicketAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      
      .addCase(TransferTicketAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success(action.payload.message)
       
      })
  },
});



// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`


export const TicketsList = (state) => state.ticketDetails.Tickets;
export const AssignedTicketsList = (state) => state.ticketDetails.AssignedTickets;
export const ClaimRequestsList = (state) => state.ticketDetails.ClaimRequests;
export const TicketDetails = (state) => state.ticketDetails.SingleTicket;
export const AssignTicketDetails = (state) => state.ticketDetails.SingleAssignTicket;
export const CompletedTicketList = (state) => state.ticketDetails.CompletedTickets;
export const FetchAssignTicketComments = (state) => state.ticketDetails.AssignTicketComments;

export const FetchTicketComments = (state) => state.ticketDetails.TicketComments;
export const GetRequestComments = (state) => state.ticketDetails.RequestComments;

export const GetSpecificClaimRequest = (state) => state.ticketDetails.SingleRequest;
export default TicketSlice.reducer;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import {

    MDBRow, MDBCol, MDBCard, MDBBtn, MDBContainer, MDBCardBody,

} from 'mdb-react-ui-kit';
import { TicketsList, SetRequestAsClaimedAsync, SetRequestAsRejectedAsync } from '../../reducers/TicketSlice'

import { getAdminToken } from '../../reducers/AuthSlice'
import { getUsersList, GetUserListAsync } from '../../reducers/UserSlice'
export default function UpdateRequest() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const tab = location.state.tab
    const token = useSelector(getAdminToken)

    useEffect(() => {
        dispatch(GetUserListAsync({ token }));
    }, []);
    const params = useParams()
    const Ticketsdata = useSelector(TicketsList);

    const [formdata, setdata] = useState({

        comment: '',
        ticketid: params.ticketid

    })
    const [rejectdata, setrejectdata] = useState({

        comment: '',
        ticketid: params.ticketid

    })
    const handlecomment = (event) => {
        setdata((prevDict) => {
            return {
                ...prevDict,
                [event.target.name]: event.target.value
            }
        })
    };
    const handlerejectcomment = (event) => {
        setrejectdata((prevDict) => {
            return {
                ...prevDict,
                [event.target.name]: event.target.value
            }
        })
    };

    const ClaimRequest = () => {

        dispatch(SetRequestAsClaimedAsync({ formdata, token })).then(() => {
            navigate("/home/backoffice/ClaimRequests")
            setdata(' ')
        });

    }
    const RejectRequest = () => {

        dispatch(SetRequestAsRejectedAsync({ rejectdata, token })).then(() => {
            navigate("/home/backoffice/ClaimRequests")

        });

    }



    return (
        <>

            {(tab == "tab1") ? <MDBContainer className="py-4 text-start ">
                <MDBCard alignment='left' style={{ borderColor: "#1c1c52" }} >
                    <MDBRow className='px-1'>
                        <MDBCol lg='10' md='8' sm='8' >
                            <h3 className='text-start m-3' style={{ color: "#13538a" }}>Claim Request</h3>
                        </MDBCol>
                        <MDBCol lg='2' md='4' sm='4'>
                            <Link to={"/home/backoffice/ClaimRequests"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3'> Back</MDBBtn></Link>
                        </MDBCol>
                        <MDBCardBody>
                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                                    Claim Request of Ticket ID  : {params.ticketid}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ color: "#1c1c52" }}>
                                    <form>
                                        <div className="text-start">
                                            <MDBRow>

                                                <MDBCol sm='12' lg='9'>
                                                    <label>Comment</label>
                                                    <textarea className="form-control fw-bold text-dark mb-2" rows="4"
                                                        group
                                                        type="text"
                                                        validate
                                                        error="wrong"
                                                        success="right"
                                                        name="comment"
                                                        value={formdata.comment}
                                                        onChange={handlecomment}
                                                    ></textarea></MDBCol>

                                            </MDBRow>
                                        </div>

                                        <div className="text-center w-100">
                                            <MDBBtn type={"button"} className="mx-2  my-5" style={{ backgroundColor: "#13538a" }} onClick={() => {
                                                ClaimRequest()
                                            }}>Claim Request</MDBBtn>
                                        </div>

                                    </form>
                                </MDBCol>
                            </MDBRow>

                        </MDBCardBody>
                    </MDBRow>
                </MDBCard>

            </MDBContainer> : <></>}

            {(tab == "tab2") ? <MDBContainer className="py-4 text-start ">
                <MDBCard alignment='left' style={{ borderColor: "#1c1c52" }} >
                    <MDBRow className='px-1'>
                        <MDBCol lg='10' md='8' sm='8'>
                            <h3 className='text-start m-3' style={{ color: "#13538a" }}>Reject Request</h3>
                        </MDBCol>
                        <MDBCol lg='2' md='4' sm='4'>
                            <Link to={"/home/backoffice/ClaimRequests"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3'> Back</MDBBtn></Link>
                        </MDBCol>
                        <MDBCardBody>
                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                                    Reject Request of Ticket ID  : {params.ticketid}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ color: "#1c1c52" }}>
                                    <form>
                                        <div className="text-start">
                                            <MDBRow>
                                                <MDBCol sm='12' lg='9'>
                                                    <label>Comment</label>
                                                    <textarea className="form-control fw-bold text-dark mb-2" rows="4"
                                                        group
                                                        type="text"
                                                        validate
                                                        error="wrong"
                                                        success="right"
                                                        name="comment"
                                                        value={rejectdata.comment}
                                                        onChange={handlerejectcomment}
                                                    ></textarea></MDBCol>
                                            </MDBRow>
                                        </div>

                                        <div className="text-center w-100">
                                            <MDBBtn type={"button"} className="mx-2  my-5" style={{ backgroundColor: "#13538a" }} onClick={() => {
                                                RejectRequest()
                                            }}>Reject Request</MDBBtn>
                                        </div>

                                    </form>
                                </MDBCol>
                            </MDBRow>

                        </MDBCardBody>
                    </MDBRow>
                </MDBCard>

            </MDBContainer> : <></>}


        </>
    );
}
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import "../../App.css"
import {

    MDBRow, MDBCol, MDBCard, MDBBtn, MDBContainer, MDBCardBody,
    MDBInput
} from 'mdb-react-ui-kit';
import { TicketsList, AssignTicketAsync,GetSpecificTicketAsync, TicketDetails,TransferTicketAsync, DiscardTicketAsync } from '../../reducers/TicketSlice'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { getAdminToken } from '../../reducers/AuthSlice'
import { getUsersList, GetUserListAsync } from '../../reducers/UserSlice'
export default function UpdateTicket() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const tab = location.state.tab
    const page = location.state.page
    const token = useSelector(getAdminToken)

    useEffect(() => {
        dispatch(GetUserListAsync({ token }));
        dispatch(GetSpecificTicketAsync({ params: { ticketid: params.ticketid, }, token }));
    }, []);
    const userdata = useSelector(getUsersList);
    const ticketdata = useSelector(TicketDetails);
    const [iconsActive, setIconsActive] = useState('tab1');
    const params = useParams()

    // const Ticketsdata = useSelector(TicketsList);
    // const ticketdata = Ticketsdata.filter((item) => {
    //     if (item.ticketid == params.ticketid) {
    //         return item
    //     }
    // })
    const [formdata, setdata] = useState({
        transferto: "",
        comment: '',
        ticketid: params.ticketid,
        id: params.id

    })
    const [discarddata, setdiscarddata] = useState({

        comment: '',
        ticketid: params.ticketid,
        id: params.id

    })
    const [assigndata, setassigndata] = useState({
        userid: "",
        comment: '',
        ticketid: params.ticketid

    })
    const [userdetails, showuserdetails] = useState(false)
    const [usertable, showusertable] = useState(true)
    const [assignuserdetails, showassignuserdetails] = useState(false)
    const [assignusertable, showassignusertable] = useState(true)

    const [assignsearch, setassignsearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const handleSearch = (e) => {
        showusertable(true)
        showuserdetails(false)
        e.preventDefault();
        setSearchInput(e.target.value);

    };
    const handleassignsearch = (e) => {
        showassignusertable(true)
        showassignuserdetails(false)
        e.preventDefault();
        setassignsearch(e.target.value);

    };

    const handleIconsClick = (value) => {
        if (value === iconsActive) {
            return;
        }

        setIconsActive(value);
    };
    const handlecomment = (event) => {
        setdata((prevDict) => {
            return {
                ...prevDict,
                [event.target.name]: event.target.value
            }
        })
    };
    const handlediscardcomment = (event) => {
        setdiscarddata((prevDict) => {
            return {
                ...prevDict,
                [event.target.name]: event.target.value
            }
        })
    };
    const handleassigncomment = (event) => {
        setassigndata((prevDict) => {
            return {
                ...prevDict,
                [event.target.name]: event.target.value
            }
        })
    };
    const customStyles = {
        table: {
            style: {
                color: "#13538a",
                backgroundColor: "transparent",
            },
        },

        head: {
            style: {
                color: "white",
                fontSize: '12px',
                fontWeight: 600,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#13538a",
                minHeight: '52px',
                borderBottomWidth: '2px',
                borderBottomColor: "white",
                borderBottomStyle: 'solid',
            },

        },
        rows: {
            style: {
                fontSize: '13px',
                fontWeight: 400,
                color: "black",
                backgroundColor: "transparent",
                minHeight: '48px',
                '&:not(:last-of-type)': {
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    borderBottomColor: "#13538a",
                },
            },
            // denseStyle: {
            //     minHeight: '32px',
            // },
            stripedStyle: {
                color: "black",
                backgroundColor: "transparent",
            },
        },

        headCells: {
            style: {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
            draggingStyle: {
                cursor: 'move',
            },
        },

        pagination: {
            style: {
                color: "#13538a",
                fontSize: '13px',
                minHeight: '56px',
                backgroundColor: "transparent",
                borderTopStyle: 'solid',
                borderTopWidth: '2px',
                borderTopColor: "#13538a"
            },
            pageButtonsStyle: {

                height: '40px',
                width: '40px',
                paddingLeft: '0px',
                paddingRight: '0px',
                color: "#13538a",
                fill: "#13538a",
                backgroundColor: 'transparent',

            },
        },
    }
    const userColumns = [

        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Username',
            selector: row => row.username,
        },
        {
            name: 'Email',
            selector: row => row.email,
            grow: 2
        },
        {
            name: 'CNIC',
            selector: row => row.cnic,
            grow: 2
        },


    ];
    const usertabledata = userdata.filter(item => {
        if (searchInput.length == 0) {
            return item;
        }
        else if (item.username.toLowerCase().includes(searchInput.toLowerCase())) {
            return item;
        }

    });
    const userassigntabledata = userdata.filter(item => {
        if (assignsearch.length == 0) {
            return item;
        }
        else if (item.username.toLowerCase().includes(assignsearch.toLowerCase())) {
            return item;
        }

    });
    const fun = (row) => {

        setSearchInput(row.username);
        setdata((prevDict) => {
            return {
                ...prevDict,
                "transferto": row.id
            }
        })
        showuserdetails(true)
        showusertable(false)


    }
    const assignfun = (row) => {

        setassignsearch(row.username);
        setassigndata((prevDict) => {
            return {
                ...prevDict,
                "userid": row.id
            }
        })
        showassignuserdetails(true)
        showassignusertable(false)


    }
    const assignuser = userdata.filter((item) => {
        if (item.username == assignsearch) {
            return item
        }
    })
    const user = userdata.filter((item) => {
        if (item.username == searchInput) {
            return item
        }
    })

    const AssignUser = () => {
        dispatch(AssignTicketAsync({ assigndata, token })).then(() => {
            navigate("/home/backoffice/Tickets")
        });

    }
    const TransferTicket = () => {

        dispatch(TransferTicketAsync({ formdata, token })).then(() => {
            navigate("/home/backoffice/Tickets")
            setdata(' ')
        });

    }
    const DiscardTicket = () => {

        dispatch(DiscardTicketAsync({ discarddata, token })).then(() => {
            navigate("/home/backoffice/Tickets")

        });

    }

    return (
        <>


            {(tab == "tab1") && (<MDBContainer className="py-4 text-start ">
                <MDBCard alignment='left' style={{ borderColor: "#1c1c52" }} >
                    <MDBRow className='px-1'>
                        <MDBCol lg='10' md='8' sm='8'>
                            <h3 className='text-start m-3' style={{ color: "#13538a" }}>Transfer Ticket</h3>
                        </MDBCol>
                        <MDBCol lg='2' md='4' sm='4'>
                            <Link to={"/home/backoffice/Tickets"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3 px-3 py-2'> Back</MDBBtn></Link>
                        </MDBCol>
                        <MDBCardBody>
                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white",fontSize:"12px" }}>
                                    Ticket: {params.ticketid} Ref # {ticketdata[0]?.referencenum}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ color: "#1c1c52" }}>
                                    <form>
                                        <div className="text-start">
                                            <MDBRow>
                                                <MDBCol sm='12' lg='3' style={{ marginTop: "27px" }} >
                                                    <MDBInput label="Transfer to Username" icon="search" type="search" error="wrong"
                                                        success="right" name="search" value={searchInput} onChange={handleSearch} />
                                                </MDBCol>
                                                <MDBCol sm='12' lg='9'>
                                                    <label>Comment</label>
                                                    <textarea className="form-control fw-bold text-dark mb-2" rows="4"
                                                        group
                                                        type="text"
                                                        validate
                                                        error="wrong"
                                                        success="right"
                                                        name="comment"
                                                        value={formdata.comment}
                                                        onChange={handlecomment}
                                                    ></textarea></MDBCol>

                                            </MDBRow>
                                            {usertable && <div>
                                                <DataTable

                                                    pagination="true"
                                                    columns={userColumns}
                                                    data={usertabledata}
                                                    highlightOnHover
                                                    fixedHeader
                                                    responsive
                                                    pointerOnHover
                                                    striped
                                                    customStyles={customStyles}
                                                    onRowClicked={fun}
                                                />
                                            </div>}

                                            {
                                                userdetails && <div>
                                                    <div >
                                                        <MDBRow className='px-2' >
                                                            <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                                                                Details of User Id : {formdata.userid} with Username: {user[0].username}
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <MDBRow className='m-2' style={{ borderColor: "#13538a" }}>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Name: </b><span>{user[0]["name"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Username: </b><span>{user[0]["username"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Email: </b><span>{user[0]["email"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>CNIC: </b><span>{user[0]["cnic"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>City: </b><span>{user[0]["city"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Details: </b><span>{user[0]["details"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Region: </b><span>{user[0]["region"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Home Address: </b><span>{user[0]["homeaddress"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Latitude: </b><span>{user[0]["homelat"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Longitude: </b><span>{user[0]["homelong"]}</span>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </div>

                                                </div>
                                            }
                                        </div>

                                        <div className="text-center w-100">
                                            <MDBBtn type={"button"} className="mx-2  my-5" style={{ backgroundColor: "#13538a" }} onClick={() => {
                                                let allow = true;
                                                if (!formdata.transferto) {
                                                    toast.error("Provide a valid username")
                                                    allow = false;
                                                }

                                                else if (allow) { TransferTicket() }
                                            }}>Transfer Ticket</MDBBtn>
                                        </div>

                                    </form>
                                </MDBCol>
                            </MDBRow>

                        </MDBCardBody>
                    </MDBRow>
                </MDBCard>

            </MDBContainer>)}


            {(page == "tab2") && (<MDBContainer className="py-4 text-start ">
                <MDBCard alignment='left' style={{ borderColor: "#1c1c52" }} >
                    <MDBRow className='px-1'>
                        <MDBCol lg='10' md='8' sm='8'>
                            <h3 className='text-start m-3' style={{ color: "#13538a" }}>Assign Ticket</h3>
                        </MDBCol>
                        <MDBCol lg='2' md='4' sm='4'>
                            <Link to={"/home/backoffice/Tickets"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3 px-3 py-2'> Back</MDBBtn></Link>
                        </MDBCol>

                        <MDBCardBody>
                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white",fontSize:"12px" }}>
                                    Ticket: {params.ticketid} Ref # {ticketdata[0]?.referencenum}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ color: "#1c1c52" }}>
                                    <form>
                                        <div className="text-start">
                                            <MDBRow>
                                                <MDBCol sm='12' lg='3' style={{ marginTop: "27px" }} >
                                                    <MDBInput label="Assign to Username" icon="search" type="search" error="wrong"
                                                        success="right" name="search" value={assignsearch} onChange={handleassignsearch} />
                                                </MDBCol>
                                                <MDBCol sm='12' lg='9'>
                                                    <label>Comment</label>
                                                    <textarea className="form-control fw-bold text-dark mb-2" rows="4"
                                                        group
                                                        type="text"
                                                        validate
                                                        error="wrong"
                                                        success="right"
                                                        name="comment"
                                                        value={assigndata.comment}
                                                        onChange={handleassigncomment}
                                                    ></textarea></MDBCol>

                                            </MDBRow>
                                            {assignusertable && <div>
                                                <DataTable

                                                    pagination="true"
                                                    columns={userColumns}
                                                    data={userassigntabledata}
                                                    highlightOnHover
                                                    fixedHeader
                                                    responsive
                                                    pointerOnHover
                                                    striped
                                                    customStyles={customStyles}

                                                    onRowClicked={assignfun}


                                                />
                                            </div>}

                                            {
                                                assignuserdetails && <div>
                                                    <div >
                                                        <MDBRow className='px-2' >
                                                            <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                                                                Details of User Id : {formdata.userid} with Username: {assignuser[0].username}
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <MDBRow className='m-2' style={{ borderColor: "#13538a" }}>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Name: </b><span>{assignuser[0]["name"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Username: </b><span>{assignuser[0]["username"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Email: </b><span>{assignuser[0]["email"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>CNIC: </b><span>{assignuser[0]["cnic"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>City: </b><span>{assignuser[0]["city"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Details: </b><span>{assignuser[0]["details"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Region: </b><span>{assignuser[0]["region"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Home Address: </b><span>{assignuser[0]["homeaddress"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Latitude: </b><span>{assignuser[0]["homelat"]}</span>
                                                            </MDBCol>
                                                            <MDBCol lg='5' sm='12' md='12' className='text-start py-2 fs-5' >
                                                                <b style={{ color: "#13538a" }}>Longitude: </b><span>{assignuser[0]["homelong"]}</span>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </div>

                                                </div>
                                            }
                                        </div>

                                        <div className="text-center w-100">
                                            <MDBBtn type={"button"} className="mx-2  my-5" style={{ backgroundColor: "#13538a" }} onClick={() => {
                                                let allow = true;
                                                if (!assigndata.userid) {
                                                    toast.error("Provide a valid username")
                                                    allow = false;
                                                }

                                                else if (allow) { AssignUser() }
                                            }}>Assign Ticket</MDBBtn>
                                        </div>

                                    </form>
                                </MDBCol>
                            </MDBRow>

                        </MDBCardBody>
                    </MDBRow>
                </MDBCard>

            </MDBContainer>)}



            {(page == "tab3") && (<MDBContainer className="py-4 text-start ">
                <MDBCard alignment='left' style={{ borderColor: "#1c1c52" }} >
                    <MDBRow className='px-1'>
                        <MDBCol lg='10' md='8' sm='8'>
                            <h3 className='text-start m-3' style={{ color: "#13538a" }}>Discard Ticket</h3>
                        </MDBCol>
                        <MDBCol lg='2' md='4' sm='4'>
                            <Link to={"/home/backoffice/Tickets"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3 text-end m-3 px-3 py-2'> Back</MDBBtn></Link>
                        </MDBCol>
                        <MDBCardBody>
                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white",fontSize:"12px" }}>
                                    Ticket: {params.ticketid} Ref # {ticketdata[0]?.referencenum}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='px-2' >
                                <MDBCol lg='12' className='p-2 text-start' style={{ color: "#1c1c52" }}>
                                    <form>
                                        <div className="text-start">
                                            <MDBRow>

                                                <MDBCol sm='12' lg='9'>
                                                    <label>Comment</label>
                                                    <textarea className="form-control fw-bold text-dark mb-2" rows="4"
                                                        group
                                                        type="text"
                                                        validate
                                                        error="wrong"
                                                        success="right"
                                                        name="comment"
                                                        value={discarddata.comment}
                                                        onChange={handlediscardcomment}
                                                    ></textarea></MDBCol>

                                            </MDBRow>

                                        </div>

                                        <div className="text-center w-100">
                                            <MDBBtn type={"button"} className="mx-2  my-5" style={{ backgroundColor: "#13538a" }} onClick={() => {
                                                DiscardTicket()
                                            }}>Discard Ticket</MDBBtn>
                                        </div>

                                    </form>
                                </MDBCol>
                            </MDBRow>

                        </MDBCardBody>
                    </MDBRow>
                </MDBCard>

            </MDBContainer>)}

        </>
    );
}
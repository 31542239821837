
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useNavigate } from "react-router-dom";

import {

    MDBRow,
    MDBCol,
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBContainer,
    MDBBtn,
    MDBInput


} from 'mdb-react-ui-kit';
import { getAdminToken } from '../../reducers/AuthSlice'

import { getUsersList, GetSingleUserAsync, getSpecificUser, UpdateUserAsync } from '../../reducers/UserSlice'
import { toast } from 'react-toastify';
export const UpdateUser = () => {
    const params = useParams()
    const token = useSelector(getAdminToken);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(GetSingleUserAsync({ params: { userid: params.userid }, token }));
    }, []);
    const user = useSelector(getSpecificUser);
    const [formdata, setdata] = useState({
        name: '',
        username: '',
        cnic: '',
        city: '',
        details: '',
        email: '',
        homeaddress: '',
        homelat: '',
        homelong: '',
        region: '',
    })
    useEffect(() => {
        if (user) {
            setdata(user)
        }
    }, [user]);
    // const userDetails = useSelector(getUsersList);
    // const userData = userDetails.filter((item) => {
    //     if (item.id == params.userid) {
    //         return item
    //     }
    // })
    // const [formdata, setdata] = useState({
    //     name: userData[0].name,
    //     username: userData[0].username,
    //     cnic: userData[0].cnic,
    //     city: userData[0].city,
    //     details: userData[0].details,
    //     email: userData[0].email,
    //     homeaddress: userData[0].homeaddress,
    //     homelat: userData[0].homelat,
    //     homelong: userData[0].homelong,
    //     region: userData[0].region,
    // })

    const handleAllChanges = (event) => {
        setdata(
            (PrevDict) => {
                return {
                    ...PrevDict,
                    [event.target.name]: event.target.value
                }
            }
        )
    }
    const updateUser = () => {
        dispatch(UpdateUserAsync({ formdata, token })).then(() => {
            navigate("/home/backoffice/Userlist")
        });
    }


    return (
        <MDBContainer className="py-4 text-start ">
            <MDBCard alignment='left' style={{ borderColor: "#1c1c52" }} >
                <MDBRow className='px-1'>

                    <MDBCol lg='10' md='8' sm='12'>
                        <h3 className='text-start m-3' style={{ color: "#13538a" }}>Update User</h3>
                    </MDBCol>
                    <MDBCol lg='2' md='4' sm='12'>
                        <Link to={"/home/backoffice/Userlist"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3 px-3 py-2'> Back</MDBBtn></Link>
                    </MDBCol>
                    {
                        user && (
                            <MDBCardBody>
                                <MDBRow className='px-2' >
                                    <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                                        Details of User ID: {params.userid} with Username: {user.username}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='px-2' >
                                    <MDBCol lg='12' className='p-2 text-start' style={{ color: "#1c1c52" }}>
                                        <form>
                                            <div className="text-start">
                                                <MDBRow>

                                                    <MDBCol lg="6" className="py-2">
                                                        <MDBInput label="Name" type="text" error="wrong"
                                                            success="right" name="name" value={formdata.name} onChange={handleAllChanges} />
                                                    </MDBCol>
                                                    <MDBCol lg="6" className="py-2">
                                                        <MDBInput label="CNIC (without hyphens)" icon="envelope" type="text" error="wrong"
                                                            success="right" name="cnic" value={formdata.cnic} onChange={handleAllChanges} />
                                                    </MDBCol>

                                                    <MDBCol lg="6" className="py-2">
                                                        <MDBInput label="Email" icon="envelope" type="text" error="wrong"
                                                            success="right" name="email" value={formdata.email} onChange={handleAllChanges} />
                                                    </MDBCol>  <MDBCol lg="6" className="py-2">
                                                        <MDBInput label="City" type="text" error="wrong"
                                                            success="right" name="city" value={formdata.city} onChange={handleAllChanges} />
                                                    </MDBCol>
                                                    <MDBCol lg="12" className="py-2">
                                                        <MDBInput label="Details" icon="envelope" type="text" error="wrong"
                                                            success="right" name="details" value={formdata.details} onChange={handleAllChanges} />
                                                    </MDBCol>
                                                    <MDBCol lg="12" className="py-2">
                                                        <MDBInput label="Address" icon="envelope" type="text" error="wrong"
                                                            success="right" name="homeaddress" value={formdata.homeaddress} onChange={handleAllChanges} />
                                                    </MDBCol>
                                                    <MDBCol lg="4" className="py-2">
                                                        <MDBInput label="Latitude" type="text" error="wrong"
                                                            success="right" name="homelat" value={formdata.homelat} onChange={handleAllChanges} />
                                                    </MDBCol>

                                                    <MDBCol lg="4" className="py-2">
                                                        <MDBInput label="Longititude" icon="envelope" type="text" error="wrong"
                                                            success="right" name="homelong" value={formdata.homelong} onChange={handleAllChanges} />
                                                    </MDBCol>
                                                    <MDBCol lg="4" className="py-2">
                                                        <MDBInput label="Region" type="text" error="wrong"
                                                            success="right" name="region" value={formdata.region} onChange={handleAllChanges} />
                                                    </MDBCol>
                                                </MDBRow>
                                            </div>
                                            <div className="text-center w-100">
                                                <MDBBtn type={"button"} className="mx-2  my-5" style={{ backgroundColor: "#13538a" }} onClick={() => {
                                                    let allow = true;
                                                    if (!formdata.name) {
                                                        toast.error("Name not provided.")
                                                        allow = false;
                                                    }
                                                    else if (!formdata.email) {
                                                        toast.error("Email not provided.")
                                                        allow = false;
                                                    }
                                                    else if (!formdata.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                                                        toast.error("Provide a valid email")
                                                        allow = false;
                                                    }
                                                    else if (!formdata.details) {
                                                        toast.error("Details not provided.")
                                                        allow = false;
                                                    }
                                                    else if (!formdata.city) {
                                                        toast.error("City not provided.")
                                                        allow = false;
                                                    }
                                                    else if (formdata.city.match(/\d/)) {
                                                        toast.error("Provide a valid city");
                                                        allow = false;
                                                    }
                                                    else if (formdata.cnic.match(/[a-zA-Z]/)) {
                                                        toast.error("Provide a valid cnic");
                                                        allow = false;
                                                    }
                                                    else if (!formdata.cnic) {
                                                        toast.error("CNIC not provided.")
                                                        allow = false;
                                                    }

                                                    else if (formdata.cnic.length != 13) {
                                                        toast.error("Provide a valid CNIC")
                                                        allow = false;
                                                    }
                                                    else if (!formdata.cnic.match(/^\d{5}\d{7}\d$/)) {
                                                        toast.error("Provide a valid CNIC number without hyphens");
                                                        allow = false;
                                                    }

                                                    else if (!formdata.homelat) {
                                                        toast.error("Latitude not provided.")
                                                        allow = false;
                                                    }
                                                    else if (formdata.homelat.match(/[a-zA-Z]/)) {
                                                        toast.error("Provide a valid latitude (numeric value only)");
                                                        allow = false;
                                                    }
                                                    else if (!formdata.homeaddress) {
                                                        toast.error("Address not provided.")
                                                        allow = false;
                                                    }
                                                    else if (!formdata.homelong) {
                                                        toast.error("Longitude not provided.")
                                                        allow = false;
                                                    }
                                                    else if (formdata.homelong.match(/[a-zA-Z]/)) {
                                                        toast.error("Provide a valid longitude (numeric value only)");
                                                        allow = false;
                                                    }
                                                    else if (!formdata.region) {
                                                        toast.error("Region not provided.")
                                                        allow = false;
                                                    }
                                                    else if (formdata.region.match(/\d/)) {
                                                        toast.error("Provide a valid Region");
                                                        allow = false;
                                                    }
                                                    else if (allow) { updateUser() }
                                                }}>Update User</MDBBtn>
                                            </div>

                                        </form>
                                    </MDBCol>
                                </MDBRow>

                            </MDBCardBody>
                        )
                    }

                </MDBRow>
            </MDBCard>

        </MDBContainer>
    );
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useLocation } from "react-router-dom";

import {

  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBBtn,
  MDBPagination,
  MDBPaginationLink,
  MDBPaginationItem


} from 'mdb-react-ui-kit';
import { TicketDetails, GetSpecificTicketAsync, GetTicketCommentsAsync, FetchTicketComments } from '../../reducers/TicketSlice'
import { getAdminToken } from '../../reducers/AuthSlice'

const TicketDict = { "0": "Unassigned", "1": "Assigned", "2": "Completed", "3": "Discarded" };

const priorityMap = { "1": "High", "2": "Medium", "3": "Low" };
export const ViewTicket = () => {
  const dispatch = useDispatch();
  const params = useParams()
  const token = useSelector(getAdminToken);
  // const location = useLocation()
  // const data = location.state?.data
  const [currentPage, setCurrentPage] = useState(1);

  const [displayedComments, setDisplayedComments] = useState([]);
  const pageSize = 3;
  const setData = (pageNumber) => {
    setCurrentPage(pageNumber);

    // Calculate the start and end index for slicing the comments array
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Slice the comments array based on the calculated indices
    const pageComments = comments?.comments.slice(startIndex, endIndex);

    // Set the sliced comments in the state
    setDisplayedComments(pageComments);
  }
  useEffect(() => {
    dispatch(GetSpecificTicketAsync({ params: { ticketid: params.ticketid, }, token }));
    dispatch(GetTicketCommentsAsync({ params: { ticketid: params.ticketid }, token }));
  }, []);


  let comments = useSelector(FetchTicketComments)[0];
  const ticket = useSelector(TicketDetails);
  useEffect(() => {
    if (comments) {
      setDisplayedComments(comments?.comments.slice(0, pageSize))
    }
  }, [comments]);
  let pagecount = Math.ceil((comments?.comments.length) / 3)
  const paginationItems = [];
  for (let i = 1; i <= pagecount; i++) {
    paginationItems.push(
      <MDBPaginationItem key={i}>
        <MDBPaginationLink >
          <MDBBtn onClick={() => setData(i)} className='px-2 py-1' color='primary' rippleColor='dark'>{i}</MDBBtn>
        </MDBPaginationLink>
      </MDBPaginationItem>
    );
  }

  //   const ticket = useSelector(TicketDetails);


  return (
    <MDBContainer className="py-4 text-start ">

      <MDBCard alignment='left' style={{ borderColor: "#13538a" }} >
        <MDBRow className='px-1'>
          <MDBCol lg='10' md='8' sm='8'>
            <h3 className='text-start m-3' style={{ color: "#13538a" }}>Ticket Information</h3>
          </MDBCol>
          <MDBCol lg='2' md='4' sm='4'>
            <Link to={"/home/backoffice/Tickets"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} className='text-end m-3'> Back</MDBBtn></Link>
          </MDBCol>
          <MDBCardBody className='small-text'>
            <MDBRow className='px-2' >
              <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                Details of Ticket with ID : {params.ticketid}
              </MDBCol>
            </MDBRow>
            {
              ticket?.length > 0 && (
                <MDBRow className='m-2' style={{ borderColor: "#13538a" }}>
                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Reference Numer: </b><span>{ticket[0]?.referencenum}</span>
                  </MDBCol>

                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Priority: </b><span>{priorityMap[ticket[0]?.priority]}</span>
                  </MDBCol>

                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Details: </b><span>{ticket[0]?.details}</span>
                  </MDBCol>

                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>ATM Location: </b><span>{ticket[0]?.atmlocation}</span>
                  </MDBCol>

                  <MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Status: </b><span>{TicketDict[ticket[0]?.status]}</span>
                  </MDBCol><MDBCol lg='5' sm='12' md='12' className='text-start py-2' >
                    <b style={{ color: "#13538a" }}>Description: </b><span>{ticket[0]?.description}</span>
                  </MDBCol>
                </MDBRow>
              )
            }

          </MDBCardBody>
        </MDBRow>
      </MDBCard>



      <MDBCard alignment='left' style={{ borderColor: "#13538a" }} className='mt-2' >
        <MDBRow className='px-1'>

          <MDBCol lg='10' >
            <h3 className='text-start m-3' style={{ color: "#13538a" }}>View Comments</h3>
          </MDBCol>

          <MDBCardBody>
            <MDBRow className='px-2' >
              <MDBCol lg='12' className='p-2 text-start' style={{ backgroundColor: "#13538a", color: "white" }}>
                Comments Ticket with ID : {params.ticketid}
              </MDBCol>
            </MDBRow>
            <MDBRow className='m-2' style={{ borderColor: "#13538a" }}>

              {
                (displayedComments?.length > 0) ?
                  (
                    displayedComments.map((val) => {
                      let date = new Date(val["createdAt"])

                      return (
                        <MDBCol lg='8'>
                          <MDBCard style={{ backgroundColor: "#CFD8DC" }} className='mt-3'>
                            <MDBRow >
                              <MDBRow className='me-1 ms-1 mt-1'>
                                <MDBCol className='col-lg-8 col-md-8 col-sm-12' >
                                  <p className='text-start text-primary fw-bold text-capitalize' >{val["name"]}</p>
                                </MDBCol>
                                <MDBCol className='col-lg-4 col-md-4 col-sm-12'>
                                  <small className='text-primary fw-bold' >{date.toLocaleDateString()}</small>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow className='me-2 ms-2 mb-2'>
                                <p className='text-justify text-start text-dark p-1 text-capitalize' >{val["comment"]}</p>
                              </MDBRow>
                              <MDBRow className='me-2 ms-2 mb-2'>
                                <MDBCol className='col-2 text-start p-0'>
                                  <span className="btn btn-primary btn-rounded px-3 py-1" style={{ fontSize: "9px" }}> {val["action"]}</span>
                                </MDBCol>
                                <MDBCol className='col-10'>

                                </MDBCol>
                              </MDBRow>
                            </MDBRow>
                          </MDBCard>
                        </MDBCol>)
                    })
                  ) :

                  (<h4 className='text-center'>No comments to show</h4>
                  )
              }

            </MDBRow>
            {
              paginationItems.length > 0 &&
              <nav aria-label='Page navigation example'>
                <MDBPagination className='mb-0'>
                  {paginationItems}
                </MDBPagination>
              </nav>
            }
          </MDBCardBody>
        </MDBRow>
      </MDBCard>
    </MDBContainer>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CanvasJSReact from '../../assets/canvasjs.react';

import {

    MDBRow,
    MDBCol,
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBContainer,
    MDBCardHeader,

} from 'mdb-react-ui-kit';
import { getToken, loginAsync, getAdminToken } from '../../reducers/AuthSlice'

import { GetUserListAsync, getUsersList } from "../../reducers/UserSlice"
import { GetATMListAsync, getATMList } from '../../reducers/ATMSlice'
import { GetClaimRequestListAsync, ClaimRequestsList, TicketsList, GetTicketsListAsync } from '../../reducers/TicketSlice'
import { Link } from 'react-router-dom';
const statusMap = { "1": "Active", "2": "Suspended", "3": "Dropped" };

const monthMap = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
export const Dashboard = () => {
    useEffect(() => {
        dispatch(GetTicketsListAsync({ token }));
        dispatch(GetUserListAsync({ token }));
        dispatch(GetATMListAsync({ token }));
        dispatch(GetClaimRequestListAsync({ token }));
    }, []);
    const today = new Date();
    const TicketMap = { "0": { "status": "Unassigned", "color": "bg-info" }, "1": { "status": "Assigned", "color": "bg-success" }, "2": { "status": "Completed", "color": "bg-warning" }, "3": { "status": "Discarded", "color": "bg-danger" } };
    const priorityMap = { "1": { "priority": "High", "color": "text-danger fw-bold" }, "2": { "priority": "Medium", "color": "text-success fw-bold" }, "3": { "priority": "Low", "color": "text-info fw-bold" } };
    const [inputMonth, setinputMonth] = useState({
        claimMonth: today.getMonth() + 1,
        ticketMonth: today.getMonth() + 1
    });
    const dispatch = useDispatch();
    const token = useSelector(getAdminToken);

    const userdata = useSelector(getUsersList);
    const Ticketsdata = useSelector(TicketsList);
    const atmdata = useSelector(getATMList);
    const ClaimRequestsdata = useSelector(ClaimRequestsList);
    let total = 0
    ClaimRequestsdata.map((val) => {
        total = total + Number(val.amount)
    })
    const handleFilter = (e) => {
        const { name, value } = e.target;
        setinputMonth({
            ...inputMonth,
            [name]: value,
        });
    };
    let TicketDict = { "totalUnassigned": 0, "totalAssigned": 0, "totalCompleted": 0, "totalDiscarded": 0 };
    let ATMDict = { "active": 0, "inactive": 0 };
    let totalUnclaimed = 0
    let totalClaimed = 0
    let totalRejected = 0
    let totalDiscarded = 0
    const tabledata = ClaimRequestsdata.filter(item => {
        const createdMonth = new Date(item.createdAt).getMonth()
        if (createdMonth + 1 == inputMonth.claimMonth) {
            if (item.status == 0) {
                totalUnclaimed += 1
            }
            else if (item.status == 1) {
                totalClaimed += 1
            }
            else if (item.status == 2) {
                totalRejected += 1
            }
            else if (item.status == 3) {
                totalDiscarded += 1
            }
            return item
        }
    });

    atmdata.map(item => {
        if (item.status == '1') {
            ATMDict.active += 1
        }
        else {
            ATMDict.inactive += 1
        }
    });

    const tableticketdata = Ticketsdata.filter(item => {
        const createdMonth = new Date(item.createdAt).getMonth()
        if (createdMonth + 1 == inputMonth.ticketMonth) {
            if (item.status == '0') {
                TicketDict.totalUnassigned += 1
            }
            else if (item.status == '1') {
                TicketDict.totalAssigned += 1
            }
            else if (item.status == '2') {
                TicketDict.totalCompleted += 1
            }
            else if (item.status == '3') {
                TicketDict.totalDiscarded += 1
            }
            return item
        }
    });

    const Requestoptions = {

        animationEnabled: true,
        title: {
            text: `Request Status for ${monthMap[inputMonth["claimMonth"] - 1]}`
        },
        data: [{
            type: "pie",
            startAngle: 75,
            toolTipContent: "<b>{label}</b>: {y}%",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}%",
            dataPoints: [
                { y: (totalClaimed * 100) / tabledata.length, label: `Claimed(${totalClaimed})` },
                { y: (totalUnclaimed * 100) / tabledata.length, label: `Unclaimed(${totalUnclaimed})` },
                { y: (totalRejected * 100) / tabledata.length, label: `Rejected(${totalRejected})` },
                { y: (totalDiscarded * 100) / tabledata.length, label: `Discarded(${totalDiscarded})` },

            ]
        }],

    }

    // Enable responsiveness

    const ATMoptions = {
        // exportEnabled: true,
        animationEnabled: true,
        title: {
            text: `ATMs Status`
        },
        data: [{
            type: "column",
            startAngle: 75,
            toolTipContent: "<b>{label}</b>: {y}%",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}%",
            dataPoints: [
                { y: ATMDict.active, label: `Active` },
                { y: ATMDict.inactive, label: `Inactive` },
            ]

        }],


    },
        // Enable responsiveness
        containerProps = {

            height: '400px',
            border: '1px solid black',
            minWidth: "400px",

        }
    const Ticketoptions = {
        exportEnabled: true,
        animationEnabled: true,
        title: {
            text: `Ticket Status for ${monthMap[inputMonth["ticketMonth"] - 1]}`
        },
        data: [{
            type: "pie",
            startAngle: 75,
            toolTipContent: "<b>{label}</b>: {y}%",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}%",
            dataPoints: [
                { y: (TicketDict.totalUnassigned * 100) / tableticketdata.length, label: `Unassigned(${TicketDict.totalUnassigned})` },
                { y: (TicketDict.totalAssigned * 100) / tabledata.length, label: `Assigned(${TicketDict.totalAssigned})` },
                { y: (TicketDict.totalCompleted * 100) / tabledata.length, label: `Completed(${TicketDict.totalCompleted})` },
                { y: (TicketDict.totalDiscarded * 100) / tabledata.length, label: `Discarded(${TicketDict.totalDiscarded}` },

            ]
        }]
    }

    const valuableStyles = {
        styleValuable: {
            fontSize: '25px',
            fontWeight: 'bold',
        },
        styleValuableIcon: {
            fontSize: '23px',
            color: '#1e1e1e'
        },
        styleValuableHint: {
            fontSize: '15px',
            margin: '0px',
            padding: '0px',
            color: '#1e1e1e'
        }
    }

    return (
        <div className="" style={{ height: "90vh", backgroundColor: '#fafafa' }}>
            <MDBContainer className='px-4'>
                <p className='text-start' style={{ fontWeight: 'bold', color: '#1e1e1e', fontSize: '25px' }}>Dashboard</p>
            </MDBContainer>
            {/* <MDBCard> */}
            <MDBContainer style={{ backgroundColor: "" }}>
                <MDBRow>
                    {/*<MDBCol lg='4' className='py-5'>
                        <p style={{ color: "#13538a", padding: '10px', fontSize: '20px', fontWeight: 'bold' }} className='text-start'>All tickets</p>
                        <div className='p-0 text-start' style={{ backgroundColor: "", overflowY: "scroll", maxHeight: "60vh" }} >

                            {Ticketsdata.map((val) => {
                                return (
                                    <>
                                        <MDBCard className='my-3 shadow-3'>
                                            <MDBCardBody className='px-3 py-3'>
                                                <b>
                                                    Ticket ID - {val.ticketid}
                                                </b>
                                                <br>
                                                </br>
                                                <span style={{ fontSize: "0.7rem", color: '#1e1e1e' }}>
                                                    Reference number: {val.referencenum}
                                                </span>
                                                <br />
                                                {/* <b className='text-dark' style={{ fontSize: "0.8rem" }}>{val.description}</b> */}
                                                {/* <br /> 
                                                <MDBIcon fas icon="map-marker" />
                                                <span className='' style={{ fontSize: "0.7rem", color: '#1e1e1e' }}>
                                                    &nbsp; {val.atmlocation}
                                                </span>
                                                <div style={{padding: '10px'}}></div>
                                                <MDBRow>
                                                    <MDBCol>
                                                        <span className={`${TicketMap[val.status].color} p-1 rounded font-tag`} style={{ color: "white" }}  >
                                                            {TicketMap[val.status].status}
                                                        </span>
                                                    </MDBCol>
                                                    <MDBCol className='text-end'>
                                                        <span className={`${(priorityMap && val && val.priority) ? priorityMap[val.priority].color : null} font-tag`}>
                                                            {(priorityMap && val && val.priority) ? priorityMap[val.priority].priority : null}
                                                        </span>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </>
                                )
                            })}
                        </div>
                        </MDBCol>*/}
                    <MDBCol lg='12'>
                        <MDBRow className='d-flex align-items-center justify-content-center p-2'>
                            <p className='text-start mx-3 pt-5' style={{color: "#13538a", fontSize: '20px', fontWeight: 'bold' }}>Overview</p>
                            <MDBCol lg='12' md='12' className='pt-4' >
                                <MDBCard>
                                    <MDBCardHeader>
                                        Todays Statistics
                                    </MDBCardHeader>
                                    <MDBRow className='py-4' style={{ color: "#13538a" }}>
                                        <MDBCol lg='4'>
                                            <MDBIcon fas icon="ticket-alt" size='1x' className='text-center' style={valuableStyles.styleValuableIcon} />
                                            <p style={valuableStyles.styleValuableHint}>Ticket in progress</p>
                                            <span style={valuableStyles.styleValuable}>{Ticketsdata.length}</span>
                                        </MDBCol>
                                        <MDBCol lg='4' >
                                            <MDBIcon fas icon="money-check-alt" size='1x' className='text-center' style={valuableStyles.styleValuableIcon} />
                                            <p style={valuableStyles.styleValuableHint}>Tickets completed</p>
                                            <span style={valuableStyles.styleValuable}>{ClaimRequestsdata.length}</span>
                                        </MDBCol>
                                        <MDBCol lg='4'>
                                            <MDBIcon fas icon="dollar-sign" size='1x' className='text-center' style={valuableStyles.styleValuableIcon} />
                                            <p style={valuableStyles.styleValuableHint}>Unassigned tickets</p>
                                            <span style={valuableStyles.styleValuable}>{isNaN(total) ? 0 : total}</span>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg='12' md='12' className='pt-4' >
                                <MDBCard>
                                    <MDBRow className='py-4' style={{ color: "#13538a" }}>
                                        <MDBCol lg='4'>
                                            <MDBIcon fas icon="ticket-alt" size='1x' className='text-center' style={valuableStyles.styleValuableIcon} />
                                            <p style={valuableStyles.styleValuableHint}>All Tickets</p>
                                            <span style={valuableStyles.styleValuable}>{Ticketsdata.length}</span>
                                        </MDBCol>
                                        <MDBCol lg='4' >
                                            <MDBIcon fas icon="money-check-alt" size='1x' className='text-center' style={valuableStyles.styleValuableIcon} />
                                            <p style={valuableStyles.styleValuableHint}>Unassigned Tickets</p>
                                            <span style={valuableStyles.styleValuable}>{ClaimRequestsdata.length}</span>
                                        </MDBCol>
                                        <MDBCol lg='4'>
                                            <MDBIcon fas icon="dollar-sign" size='1x' className='text-center' style={valuableStyles.styleValuableIcon} />
                                            <p style={valuableStyles.styleValuableHint}>Assigned Tickets</p>
                                            <span style={valuableStyles.styleValuable}>{isNaN(total) ? 0 : total}</span>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg='12' md='12' className='pt-4' >
                                <MDBCard>
                                    <MDBRow className='py-4' style={{ color: "#13538a" }}>

                                        <MDBCol lg='4' >
                                            <MDBIcon fas icon="money-check-alt" size='1x' className='text-center' style={valuableStyles.styleValuableIcon} />
                                            <p style={valuableStyles.styleValuableHint}>Claim</p>
                                            <span style={valuableStyles.styleValuable}>{ClaimRequestsdata.length}</span>
                                        </MDBCol>
                                        <MDBCol lg='4'>
                                            <MDBIcon fas icon="dollar-sign" size='1x' className='text-center' style={valuableStyles.styleValuableIcon} />
                                            <p style={valuableStyles.styleValuableHint}>Amount</p>
                                            <span style={valuableStyles.styleValuable}>{isNaN(total) ? 0 : total}</span>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCard>
                            </MDBCol>

                            <MDBCol lg='6' md='12' className='py-4' >
                                <MDBCard className='py-4' style={{ backgroundColor: "white", color: "#13538a" }}>
                                    <MDBCardBody>
                                        <MDBIcon fas icon="users" size='1x' className='text-center' style={valuableStyles.styleValuableIcon} />
                                        <p style={valuableStyles.styleValuableHint}>Users</p>
                                        <span style={valuableStyles.styleValuable}>{userdata.length}</span>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg='6' md='12'>
                                <MDBCard className='py-4' style={{ backgroundColor: "white", color: "#13538a" }}>
                                    <MDBCardBody>
                                        <MDBIcon fas icon="building" size='1x' className='text-center' style={valuableStyles.styleValuableIcon} />
                                        <p style={valuableStyles.styleValuableHint}>Active ATMs</p>
                                        <span style={valuableStyles.styleValuable}>{atmdata.length}</span>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>

                        </MDBRow>
                        {/* <MDBRow>
                            <MDBCol className='py-5' lg='12'>
                                <MDBCard alignment='center' style={{ backgroundColor: "#c4c7cc" }}>
                                    <label className='text-start px-2 py-2'>Search record by month</label>
                                    <input className="p-2 mx-2" style={{ borderRadius: "5px", borderColor: "#BDBDBD", width: "10rem" }} name="claimMonth" value={inputMonth.claimMonth} group type="number" min="1" max="12" validate error="wrong"
                                        onChange={handleFilter}
                                    />
                                    <div style={{ overflowX: "auto" }}>
                                        {tabledata.length > 0 ?

                                            <CanvasJSChart options={Requestoptions} containerProps={containerProps} />

                                            : `No record for ${monthMap[inputMonth["claimMonth"] - 1]}`}
                                    </div>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow> */}
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    {/* <MDBCol className='py-5' lg='12' style={{ overflowX: "auto" }} >

                        {atmdata.length > 0 ? <CanvasJSChart options={ATMoptions} containerProps={containerProps} /> : `No record for ${monthMap[inputMonth["claimMonth"] - 1]}`}

                        
                    </MDBCol> */}
                </MDBRow>
            </MDBContainer >
            {/* </MDBCard> */}
        </div >
    );
}

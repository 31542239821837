import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import { GridButton } from '../../CustomComponents/GridButton';
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBContainer


} from 'mdb-react-ui-kit';
import DataTable from 'react-data-table-component';
import { getAdminToken } from '../../reducers/AuthSlice'
import { GetATMListAsync, getATMList } from '../../reducers/ATMSlice'
import { Link, useNavigate } from 'react-router-dom';

export const ATMList = () => {
  const dispatch = useDispatch();
  const token = useSelector(getAdminToken);
  const [statusdata, setStatusData] = useState("-1");
  const navigate = useNavigate()
  const [searchInput, setSearchInput] = useState("");
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);

  };
  const handleFilter = (e) => {
    e.preventDefault();
    setStatusData(e.target.value);


  };

  const setATMdata = async (data) => {

    navigate(`/home/backoffice/ATMlist/update/${data.atmid}`)

  };
  useEffect(() => {

    dispatch(GetATMListAsync({ token }));
  }, []);
  const atmdata = useSelector(getATMList);

  const customStyles = {
    table: {
      style: {
        color: "#13538a",
        backgroundColor: "transparent",
      },
    },

    head: {
      style: {
        color: "white",
        fontSize: '12px',
        fontWeight: 600,
      },
    },
    headRow: {
      style: {
        backgroundColor: "#13538a",
        minHeight: '52px',
        borderBottomWidth: '2px',
        borderBottomColor: "white",
        borderBottomStyle: 'solid',
      },

    },
    rows: {
      style: {
        fontSize: '13px',
        fontWeight: 400,
        color: "black",
        backgroundColor: "transparent",
        minHeight: '48px',
        '&:not(:last-of-type)': {
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: "#13538a",
        },
      },
      // denseStyle: {
      //     minHeight: '32px',
      // },
      stripedStyle: {
        color: "black",
        backgroundColor: "transparent",
      },
    },

    headCells: {
      style: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
      draggingStyle: {
        cursor: 'move',
      },
    },

    pagination: {
      style: {
        color: "#13538a",
        fontSize: '13px',
        minHeight: '56px',
        backgroundColor: "transparent",
        borderTopStyle: 'solid',
        borderTopWidth: '2px',
        borderTopColor: "#13538a"
      },
      pageButtonsStyle: {

        height: '40px',
        width: '40px',
        color: "#13538a",
        fill: "#13538a",
        backgroundColor: 'transparent',
        paddingLeft: '0px',
        paddingRight: '0px'

      },
    },
  }

  const atmColumns = [
    {
      name: 'ID',
      selector: row => row.atmid,
    },
    {
      name: 'Details',
      selector: row => row.details,
    },
    {
      name: 'Status',
      selector: row => (row.status == "1") ? "Active" : "Inactive",
    },
    {
      name: 'Location',
      selector: row => row.location,
    },
    {
      name: 'Longitute',
      selector: row => row.gpslong,

    },
    {
      name: 'Latitude',
      selector: row => row.gpslat,

    },
    {
      name: 'Edit',
      selector: row =>

        <MDBBtn className='mx-0 p-0' color='link' rippleColor='dark' onClick={() => {
          setATMdata(row)
        }}>Edit</MDBBtn>,
    },
  ];
  let ATMtabledata = atmdata.filter(item => {
    if (searchInput.length == 0 && statusdata == "-1") {
      return item;
    }

    else if (item.details.toLowerCase().includes(searchInput.toLowerCase()) && statusdata == "-1") {

      return item;
    }
    else if (item.details.toLowerCase().includes(searchInput.toLowerCase()) && statusdata == item.status) {

      return item;
    }


  });


  return (
    <div className="" style={{ height: "90vh", backgroundColor: "#fafafa" }}>
      <MDBContainer
        className="p-4"
        style={{ height: "90vh", backgroundColor: "#fafafa" }}
      >
        <MDBRow className="px-1">
          <MDBCol lg="12">
            <h3 className="text-start m-3" style={{ color: "#13538a" }}>
              ATM List
            </h3>
          </MDBCol>
        </MDBRow>
        <MDBCardBody>
          <MDBRow >
            <MDBCol sm='9' md='9' lg='5' className='py-2'  >
              <MDBInput
                label="Search by ATM name"
                icon="serach"
                type="search"
                error="wrong"
                success="right"
                name="search"
                value={searchInput}
                onChange={handleSearch}
              // style={{ width: "100%" }} 
              />
            </MDBCol>
            <MDBCol lg='4' sm='8' md='12' className='text-start py-2' >
              <select
                className="browser-default custom-select p-2"
                style={{
                  borderRadius: "5px",
                  borderColor: "#BDBDBD",
                  width: "80%",
                }}
                value={statusdata}
                onChange={handleFilter}
              >
                <option value="-1">All</option>
                <option value="1">Active</option>
                <option value="2">Inactive</option>

              </select>
              <i
                className="fas fa-filter px-1"
                style={{ color: "#13538a" }}
              ></i>
            </MDBCol>
            {/* <MDBCol sm='12' md='12' lg='3' className='py-2 text-end'>
              <CSVLink data={atmdata} filename={"Atm details.csv"}><span style={{ color: "#13538a", fontSize: "0.7rem" }} >Download (CSV)</span></CSVLink>
            </MDBCol> */}
            <MDBCol sm="12" md="12" lg="3" className="py-2 text-end">
              <CSVLink data={atmdata} filename={"Atm details.csv"}>
                <span style={{ color: "#13538a", fontSize: "0.7rem" }}>
                  Download (CSV)
                </span>
              </CSVLink>
            </MDBCol>
          </MDBRow>

          <DataTable
            pagination="true"
            columns={atmColumns}
            data={ATMtabledata}
            highlightOnHover
            fixedHeader
            responsive
            pointerOnHover
            striped
            customStyles={customStyles}
          />
        </MDBCardBody>
      </MDBContainer>
    </div>
  );
}
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdown,
  MDBDropdownItem,
  MDBContainer,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import { GridButton } from "../../CustomComponents/GridButton";
import DataTable from "react-data-table-component";
import { getToken, loginAsync, getAdminToken } from "../../reducers/AuthSlice";

import {
  GetClaimRequestListAsync,
  ClaimRequestsList,
} from "../../reducers/TicketSlice";
import { Link } from "react-router-dom";

const statusMap = { 1: "Active", 2: "Suspended", 3: "Dropped" };
const claimDict = { "0": "Approval required", "1": "Approved", "2": "Rejected", "3": "Discarded" };
export const ClaimRequests = () => {
  const dispatch = useDispatch();
  const token = useSelector(getAdminToken);
  const [basicModal, setBasicModal] = useState(false);
  const toggleModal = () => {
    setBasicModal(!basicModal);
  };
  useEffect(() => {
    dispatch(GetClaimRequestListAsync({ token }));
  }, []);
  const ClaimRequestsdata = useSelector(ClaimRequestsList);
  const [searchInput, setSearchInput] = useState("");

  const [statusdata, setStatusData] = useState("-1");
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };
  const handleFilter = (e) => {
    e.preventDefault();
    setStatusData(e.target.value);
  };
  const customStyles = {
    table: {
      style: {
        color: "#13538a",
        backgroundColor: "transparent",
      },
    },

    head: {
      style: {
        color: "white",
        fontSize: "12px",
        fontWeight: 600,
      },
    },
    headRow: {
      style: {
        backgroundColor: "#13538a",
        minHeight: "52px",
        borderBottomWidth: "2px",
        borderBottomColor: "white",
        borderBottomStyle: "solid",
      },
    },
    rows: {
      style: {
        fontSize: "13px",
        fontWeight: 400,
        color: "black",
        backgroundColor: "transparent",
        minHeight: "48px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "#13538a",
        },
      },
      // denseStyle: {
      //     minHeight: '32px',
      // },
      stripedStyle: {
        color: "black",
        backgroundColor: "transparent",
      },
    },

    headCells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      draggingStyle: {
        cursor: "move",
      },
    },

    pagination: {
      style: {
        color: "#13538a",
        fontSize: "13px",
        minHeight: "56px",
        backgroundColor: "transparent",
        borderTopStyle: "solid",
        borderTopWidth: "2px",
        borderTopColor: "#13538a",
      },
      pageButtonsStyle: {
        height: "40px",
        width: "40px",
        color: "#13538a",
        fill: "#13538a",
        backgroundColor: "transparent",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
  };

  const ClaimRequestsColumns = [
    {
      name: "Ticket ID",
      selector: (row) => row.ticketid,
      grow: "1",
    },
    {
      name: "Requester ID",
      selector: (row) => row.claimerid,
      grow: "2",
    },
    {
      name: "Requested By",
      selector: (row) => row.name,
      grow: "3",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      grow: "1",
    },
    {
      name: `Requester's Username`,
      selector: (row) => row.username,
      grow: "5",
    },
    {
      name: `Claim Status`,
      grow: "4",
      selector: (row) => claimDict[row.status],
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <MDBDropdown
            className="py-2"
            style={{ width: "max-content", display: "inline-block" }}
          >
            <MDBDropdownToggle tag="a" style={{ cursor: "pointer" }}>
              <GridButton iconName="wrench" color="#13538a" />
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem>
                <MDBDropdownLink href={"/home/backoffice/ClaimRequests/" + row.id}>
                  <Link to={"/home/backoffice/ClaimRequests/" + row.id}>
                    View Request
                  </Link>
                </MDBDropdownLink>
              </MDBDropdownItem>
              {row.status == "0" ? (
                <MDBDropdownItem>
                  <MDBDropdownLink href={"/home/backoffice/ClaimRequests/update/" + row.ticketid}>
                    <Link
                      to={"/home/backoffice/ClaimRequests/update/" + row.ticketid}
                      state={{ tab: "tab1" }}
                    >
                      Claim Request
                    </Link>
                  </MDBDropdownLink>
                </MDBDropdownItem>
              ) : (
                <></>
              )}
              {row.status != "2" ? (
                <MDBDropdownItem>
                  <MDBDropdownLink href={"/home/backoffice/ClaimRequests/update/" + row.ticketid}>
                    <Link
                      to={"/home/backoffice/ClaimRequests/update/" + row.ticketid}
                      state={{ tab: "tab2" }}
                    >
                      Reject Request
                    </Link>
                  </MDBDropdownLink>
                </MDBDropdownItem>
              ) : (
                <></>
              )}
            </MDBDropdownMenu>
          </MDBDropdown>
        </>
      ),
    },
  ];

  let claimedrequesttable = ClaimRequestsdata.filter((item) => {
    if (searchInput.length == 0 && statusdata == "-1") {
      return item;
    } else if (searchInput.length == 0 && statusdata == item.status) {
      return item;
    } else if (item.ticketid == searchInput && statusdata == "-1") {
      return item;
    } else if (item.claimerid == searchInput && statusdata == "-1") {
      return item;
    } else if (item.claimerid == searchInput && statusdata == item.status) {
      return item;
    } else if (item.ticketid == searchInput && statusdata == item.status) {
      return item;
    } else if (
      item.username.toLowerCase().includes(searchInput.toLowerCase()) &&
      statusdata == item.status
    ) {
      return item;
    } else if (
      item.username.toLowerCase().includes(searchInput.toLowerCase()) &&
      statusdata == "-1"
    ) {
      return item;
    }
  });
  let total = 0;
  claimedrequesttable.map((val) => {
    total = total + Number(val.amount);
  });
  return (
    <div className="" style={{ height: "90vh", backgroundColor: "#fafafa" }}>
      <MDBContainer
        className="py-4 "
        style={{ height: "90vh", backgroundColor: "#fafafa" }}
      >
        <MDBRow className="d-flex align-items-center justify-content-center">
          <MDBCol lg="2" md="3" className="p-1">
            <MDBCard style={{ backgroundColor: "#13538a", color: "white" }}>
              <MDBCardBody>
                <MDBIcon
                  fas
                  icon="money-check-alt"
                  size="2x"
                  className="text-center"
                />
                <h5>
                  {claimDict[statusdata] ? claimDict[statusdata] : "Request"}
                </h5>
                {claimedrequesttable.length}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="2" md="3" className="p-1">
            <MDBCard style={{ backgroundColor: "#13538a", color: "white" }}>
              <MDBCardBody>
                <MDBIcon
                  fas
                  icon="dollar-sign"
                  size="2x"
                  className="text-center"
                />
                <h5>Amount</h5>
                {isNaN(total) ? 0 : total}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg="6">
            <h3 className="text-start m-3" style={{ color: "#13538a" }}>
              Claim Request Records
            </h3>
          </MDBCol>
          <MDBCardBody>
            <MDBRow>
              <MDBCol sm="6" md="9" lg="4" className="py-2">
                <MDBInput
                  label="Search by ATM ID, ticket ID, or username"
                  icon="serach"
                  type="search"
                  error="wrong"
                  success="right"
                  name="search"
                  value={searchInput}
                  onChange={handleSearch}
                />
              </MDBCol>
              <MDBCol lg="5" sm="12" md="12" className="text-start py-2">
                <select
                  className="browser-default custom-select p-2"
                  style={{
                    borderRadius: "5px",
                    borderColor: "#BDBDBD",
                    width: "80%",
                  }}
                  value={statusdata}
                  onChange={handleFilter}
                >
                  <option value="-1">All</option>
                  <option value="0">Unclaimed</option>
                  <option value="1">Claimed</option>
                  <option value="2">Rejected</option>
                  <option value="3">Discarded</option>
                </select>
                <i
                  className="fas fa-filter px-2"
                  style={{ color: "#13538a" }}
                ></i>
              </MDBCol>
              <MDBCol sm="12" md="12" lg="3" className="py-2 text-end">
                <CSVLink
                  data={ClaimRequestsdata}
                  filename={"Claim Request.csv"}
                >
                  <span style={{ color: "#13538a", fontSize: "0.7rem" }}>
                    Download (CSV)
                  </span>
                </CSVLink>
              </MDBCol>
            </MDBRow>
            <DataTable
              pagination="true"
              columns={ClaimRequestsColumns}
              data={claimedrequesttable}
              highlightOnHover
              fixedHeader
              responsive
              pointerOnHover
              striped
              customStyles={customStyles}
            />
          </MDBCardBody>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

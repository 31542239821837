import React from 'react';

import { Login } from './views/Authentication/Login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Landing } from './views/Landing';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, } from "react-router-dom";
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Footer } from './views/Footer';
function App() {

  //const token = useSelector(getToken);
  return (
    <div className="App" style={{ minHeight: "100%" }}>

      <Router>
        <Routes>

          < Route path="home/*" element={< Landing />} />
          <Route path="/*" element={<Login />} />

        </Routes>
      </Router>
      <ToastContainer />
     
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBContainer,
  MDBTooltip,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { getAdminToken } from "../../reducers/AuthSlice";

import { GetTicketsListAsync, TicketsList } from "../../reducers/TicketSlice";
import { Link, useNavigate } from "react-router-dom";
import { GridButton } from "../../CustomComponents/GridButton";
const statusMap = { 1: "Active", 2: "Suspended", 3: "Dropped" };
const TicketDict = {
  0: "Unassigned",
  1: "Assigned",
  2: "Completed",
  3: "Discarded",
};

const priorityMap = { 1: "High", 2: "Medium", 3: "Low" };
export const Tickets = () => {
  const dispatch = useDispatch();
  const token = useSelector(getAdminToken);
  const [statusdata, setStatusData] = useState("-1");

  useEffect(() => {
    dispatch(GetTicketsListAsync({ token }));
  }, []);
  const Ticketsdata = useSelector(TicketsList);
  const navigate = useNavigate();
  const setTicketData = async (data) => {

    console.log(data)
    navigate(`/home/backoffice/Tickets/update/${data.ticketid}`);
  };
  const customStyles = {
    table: {
      style: {
        color: "#13538a",
        backgroundColor: "transparent",
      },
    },

    head: {
      style: {
        color: "white",
        fontSize: "12px",
        fontWeight: 600,
      },
    },
    headRow: {
      style: {
        backgroundColor: "#13538a",
        minHeight: "52px",
        borderBottomWidth: "2px",
        borderBottomColor: "white",
        borderBottomStyle: "solid",
      },
    },
    rows: {
      style: {
        fontSize: "13px",
        fontWeight: 400,
        color: "black",
        backgroundColor: "transparent",
        minHeight: "48px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "#13538a",
        },
      },
      stripedStyle: {
        color: "black",
        backgroundColor: "transparent",
      },
    },

    headCells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      draggingStyle: {
        cursor: "move",
      },
    },

    pagination: {
      style: {
        color: "#13538a",
        fontSize: "13px",
        minHeight: "56px",
        backgroundColor: "transparent",
        borderTopStyle: "solid",
        borderTopWidth: "2px",
        borderTopColor: "#13538a",
      },
      pageButtonsStyle: {
        height: "40px",
        width: "40px",
        color: "#13538a",
        fill: "#13538a",
        backgroundColor: "transparent",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
  };

  const handleFilter = (e) => {
    e.preventDefault();
    setStatusData(e.target.value);
  };
  const [searchInput, setSearchInput] = useState("");
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };
  const tickettabledata = Ticketsdata.filter((item) => {
    if (searchInput.length == 0 && statusdata == "-1") {
      return item;
    } else if (
      item.atmlocation.toLowerCase().includes(searchInput.toLowerCase()) &&
      statusdata == "-1"
    ) {
      return item;
    } else if (
      item.referencenum.toLowerCase().includes(searchInput.toLowerCase()) &&
      statusdata == "-1"
    ) {
      return item;
    } else if (
      item.atmlocation.toLowerCase().includes(searchInput.toLowerCase()) &&
      statusdata == item.status
    ) {
      return item;
    } else if (
      item.referencenum.toLowerCase().includes(searchInput.toLowerCase()) &&
      statusdata == item.status
    ) {
      return item;
    }
  });
  const TicketsColumns = [
    {
      name: "Reference Number",
      selector: (row) => row.referencenum,
    },
    {
      name: "ATM Location",
      selector: (row) => row.atmlocation,
    },
    {
      name: "Status",
      selector: (row) => TicketDict[row.status],
    },
    {
      name: "Priority",
      selector: (row) => priorityMap[row.priority],
    },
    {
      name: "Details",
      selector: (row) => row.details,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Edit",
      selector: (row) => (
        <MDBBtn
          className="mx-0 p-0"
          color="link"
          rippleColor="dark"
          onClick={() => {
            // console.log(row)
            setTicketData(row);
          }}
        >
          Edit
        </MDBBtn>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <MDBDropdown
            className="py-2"
            style={{ width: "max-content", display: "inline-block" }}
          >
            <MDBDropdownToggle tag="a" style={{ cursor: "pointer" }}>
              <GridButton iconName="wrench" color="#13538a" />
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem>
                <MDBDropdownLink href={"/home/backoffice/Tickets/" + row.ticketid}>
                  <Link to={"/home/backoffice/Tickets/" + row.ticketid}>
                    View Ticket
                  </Link>
                </MDBDropdownLink>
              </MDBDropdownItem>
              {row.status == "0" ? (
                <MDBDropdownItem>
                  <MDBDropdownLink href={"/home/backoffice/Tickets/updateticket/" + row.ticketid}>
                    <Link
                      to={"/home/backoffice/Tickets/updateticket/" + row.ticketid}
                      state={{ page: "tab2" }}
                    >
                      Assign Ticket
                    </Link>
                  </MDBDropdownLink>
                </MDBDropdownItem>
              ) : (
                <></>
              )}
              {row.status != "3" ? (
                <MDBDropdownItem>
                  <MDBDropdownLink href={"/home/backoffice/Tickets/updateticket/" + row.ticketid}>
                    <Link
                      to={"/home/backoffice/Tickets/updateticket/" + row.ticketid}
                      state={{ page: "tab3" }}
                    >
                      Discard Ticket
                    </Link>
                  </MDBDropdownLink>
                </MDBDropdownItem>
              ) : (
                <></>
              )}
            </MDBDropdownMenu>
          </MDBDropdown>
        </>
      ),
    },
    {
      name: "Ticket ID",
      selector: (row) => row.ticketid,
    },
  ];
  return (
    <div className="" style={{ height: "90vh", backgroundColor: "#fafafa" }}>
      <MDBContainer
        className="p-4"
        style={{ height: "90vh", backgroundColor: "#fafafa" }}
      >
        <MDBRow className="px-1">
          <MDBCol lg="12">
            <h3 className="text-start m-3" style={{ color: "#13538a" }}>
              Ticket Records
            </h3>
          </MDBCol>
          <MDBCardBody>
            <MDBRow>
              <MDBCol sm="9" md="9" lg="6" className="py-2">
                <MDBInput
                  label="Search by reference number or ATM location"
                  icon="serach"
                  type="search"
                  error="wrong"
                  success="right"
                  name="search"
                  value={searchInput}
                  onChange={handleSearch}
                />
              </MDBCol>
              <MDBCol lg="3" sm="8" md="12" className="text-start py-2">
                <select
                  className="browser-default custom-select p-2"
                  style={{
                    borderRadius: "5px",
                    borderColor: "#BDBDBD",
                    width: "80%",
                  }}
                  value={statusdata}
                  onChange={handleFilter}
                >
                  <option value="-1">All</option>
                  <option value="0">Unassigned</option>
                  <option value="1">Assigned</option>
                  <option value="2">Completed</option>
                  <option value="3">Discarded</option>
                </select>
                <i
                  className="fas fa-filter px-1"
                  style={{ color: "#13538a" }}
                ></i>
              </MDBCol>
              {/* <MDBCol lg='3' className="py-2">
                <Link to={"/home/backoffice/Tickets/AssignedTickets"} style={{ color: "white" }}><MDBBtn style={{ backgroundColor: "#13538a", color: "white" }} >View Assigned Tickets</MDBBtn></Link>
              </MDBCol> */}
              <MDBCol sm="12" md="12" lg="3" className="py-2 text-end">
                <CSVLink data={tickettabledata} filename={"Ticket details.csv"}>
                  <span style={{ color: "#13538a", fontSize: "0.7rem" }}>
                    Download (CSV)
                  </span>
                </CSVLink>
              </MDBCol>
            </MDBRow>

            <DataTable
              pagination="true"
              columns={TicketsColumns}
              data={tickettabledata}
              highlightOnHover
              fixedHeader
              responsive
              pointerOnHover
              striped
              customStyles={customStyles}
            />
          </MDBCardBody>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

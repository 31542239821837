import apiUtil from '../utilities/apiHelper'

// export function Login(data) {
//   return apiUtil.postApi('api/user/login', "", { "f8996da763b7a969b1": data.userName, "d74ff0ee8da3b9806b": data.password })
// }
export function Login(data) {
 
  return apiUtil.postApi('auth/user/login', "", data)
}
export function AdminLogin(data) {
 
  return apiUtil.postApi('auth/admin/login', "", data)
}